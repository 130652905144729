import { ImageGenerationRequest } from '../models/imageGenerationRequest'
import { API_SERVICE_URL } from '../config/service'
const serviceConfig = require('../config/service.json')
import api from './auth/noAuthClient'

class ImageGenerationService {
    async PostImageGeneration(request: ImageGenerationRequest) {
        var url = API_SERVICE_URL + serviceConfig.aiModalImageGeneration
        return api.post(url, request)
    }
}
export default new ImageGenerationService()
