/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import { FunctionComponent } from 'react'
import { CodeBlock, dracula } from 'react-code-blocks'
import styled from 'styled-components'
import { ragModelExampleData } from '../data/ragExampleData'
import { CodeblockCustomTheme } from './code/customTheme'
import { Button, Spinner, Table } from 'react-bootstrap'
import { summarizationExampleData } from '../data/summarizationExampleData'
import { LoadingState } from '../models/loadingState'
import { ModelSubType, ModelType } from '../models/modelType'

const OutputContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    border: 1px solid rgb(101, 49, 113);
    border-radius: 0;
    //padding: 1rem 0;
`
const OutputInnerContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
`
const CompareInnerContainer = styled(OutputInnerContainer)`
    min-height: 33.5rem;
`
const PreStyle = styled.pre`
    margin: 0px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    background: transparent;
    width: 100%;
    padding: 1rem;
    max-height: 30rem;
    color: #ae81ff;
    .span {
        color: #ae81ff;
    }
`
const OutputContainerTab = styled.div`
    display: flex;
    justify-content: center;
    min-height: 31.5rem;
    max-height: 31.5rem;
    background: transparent;
    color: rgb(248, 248, 242);
    width: 100%;
    padding: 1rem;
`
const GetRAGButton = styled(Button)`
    font-family: 'IntelOne Text';
    font-style: normal;
    font-size: 1rem;
    text-decoration: none;
    border: none;
    color: #fff;
    background-color: rgb(143, 93, 162); //#78deff;
    min-width: 8.5rem;
    border-radius: 0px;
    border: none;
    height: 2.2rem;
    &:hover {
        background-color: rgb(143, 93, 162); //#78deff;
    }
    &:focus {
        background-color: rgb(143, 93, 162); //#78deff;
    }
`
const TableHeader = styled.th`
    border: 1px solid #b9b9b9;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 500;
    fontsize: 1rem;
    align-items: center;
    color: #000;
    min-width: 3rem;
`
const TableCell = styled.td`
    border: 1px solid #b9b9b9;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 400;
    fontsize: 0.85rem;
    align-items: center;
    color: #000;
    min-width: 3rem;
`
const OutputImageSpanContainer = styled.span`
    box-sizing: border-box;
    display: block;
    overflow: hidden;
    width: initial;
    height: initial;
    background: none;
    opacity: 1;
    border: 0px;
    margin: 0px;
    padding: 1rem;
    position: relative;
`

export interface IOutputProps {
    selectedModelName?: string
    chatMessage?: any
    isDiscoverCodeOutput?: boolean
    isRetrievalOutput?: boolean
    retrievalOutputData?: any
    isResetClicked?: boolean
    selectedOutputTab?: string
    isCompareOutput?: boolean
    compareOutputData?: any
    isSummarizationOutput?: any
    summarizationOutputData?: any
    summarizationOutputLoading?: string
    isVisualOutput?: boolean
    imageGenerationLoading?: LoadingState
    retrievalQueryLoading?: LoadingState
    outputImageURL?: any
    isTranslationOutput?: any
    translationOutputData?: any
    translationOutputLoading?: string
    modelType?: ModelType
    modelSubType?: ModelSubType
    isStream: boolean
}

const OutputPanel: FunctionComponent<IOutputProps> = ({
    selectedModelName,
    chatMessage,
    isDiscoverCodeOutput,
    isRetrievalOutput,
    retrievalOutputData,
    isResetClicked,
    selectedOutputTab,
    isCompareOutput,
    compareOutputData,
    isSummarizationOutput,
    summarizationOutputData,
    summarizationOutputLoading,
    isVisualOutput,
    imageGenerationLoading,
    retrievalQueryLoading,
    outputImageURL,
    isTranslationOutput,
    modelType,
    modelSubType,
    isStream,
}: IOutputProps) => {
    const escapeQuotes = (str: string) => {
        return str.replace(/'/g, "\\'").replace(/"/g, '\\"')
    }
    const stream = isStream ? 'True' : 'False'

    const commandText = `   from openai import OpenAI

    
    client = OpenAI(
      base_url = "https://or-dev.dcs-tools-experiments.infra-host.com/api/ai",
      api_key = "$API_KEY_REQUIRED_IF_EXECUTING_OUTSIDE_INTC"
    )
    
    completion = client.chat.completions.create(
      model="${selectedModelName}",
      messages="${escapeQuotes(chatMessage)}",
      temperature=0.5,
      top_p=1,
      max_tokens=512,
      stream=${stream}
    )
    
    print(completion.choices[0].message)

    `

    const commandSummarizationText = `   from openai import OpenAI

    
    client = OpenAI(
      base_url = "https://or-dev.dcs-tools-experiments.infra-host.com/api/ai",
      api_key = "$API_KEY_REQUIRED_IF_EXECUTING_OUTSIDE_INTC"
    )
    
    completion = client.chat.completions.create(
      model="${selectedModelName}",
      messages="${escapeQuotes(chatMessage)}",
      stream=${stream}
    )
    
    print(completion.choices[0].message)

    `

    const commandTranslationText = `   from openai import OpenAI
    
    client = OpenAI(
      base_url = "https://or-dev.dcs-tools-experiments.infra-host.com/api/ai",
      api_key = "$API_KEY_REQUIRED_IF_EXECUTING_OUTSIDE_INTC"
    )
    
    completion = client.chat.completions.create(
      model="${selectedModelName}",
      messages=${escapeQuotes(chatMessage)},
      max_tokens=512,
      stream=${stream}
    )
    
    print(completion.choices[0].message)

    `

    const commantImageGenrationText = `    import base64
    from openai import OpenAI
    
    
    client = OpenAI(
        base_url = "https://or-dev.dcs-tools-experiments.infra-host.com/api/ai",
        api_key = "$API_KEY_REQUIRED_IF_EXECUTING_OUTSIDE_INTC"
    )
    
    image = client.images.generate(
      model="stabilityai/stable-diffusion-2-base",
      prompt="${escapeQuotes(chatMessage)}",
      n=1
    )
    
    with open("sample.png", "wb") as fd:
        fd.write(base64.b64decode(image.b64_json))
    `
    const ChatQnAInputFileCommandText = `   from openai import OpenAI
    
    client = OpenAI(
      base_url = "https://or-dev.dcs-tools-experiments.infra-host.com/api/ai",
      api_key = "$API_KEY_REQUIRED_IF_EXECUTING_OUTSIDE_INTC"
    )    
    completion = client.chat.completions.create(
      model="${selectedModelName}",
      messages=[
        {
            "role": "system",
            "content":"<file content>"
        }
        {
            "role": "user",
            "content": "${escapeQuotes(chatMessage)}"
        }     
    ],     
      max_tokens=512,
      stream=${stream}
    )

    print(completion.choices[0].message)
    `

    const loadingSection = (
        <div style={{ marginLeft: '1.5rem' }}>
            <Spinner animation="grow" variant="info" size="sm" />
            <Spinner animation="grow" variant="info" size="sm" />
            <Spinner animation="grow" variant="info" size="sm" />
            <span> Working on it...</span>
        </div>
    )
    const ragDataLength =
        compareOutputData &&
        compareOutputData.filter((item: any) => item.isRagModel === true)

    const tableHeaders = (data: any) =>
        data && data.length > 0 ? (
            <>
                <TableHeader>Model</TableHeader>
                {compareOutputData[0].metrics.map((item: any) => {
                    return (
                        <>
                            <TableHeader>
                                <span>{item.name}</span>
                                <span> ({item.unit})</span>
                            </TableHeader>
                        </>
                    )
                })}
            </>
        ) : (
            ''
        )

    const tableRows = (data: any) =>
        data && data.length > 0
            ? data.map((message: any) => {
                  return (
                      <>
                          <tr>
                              <TableCell>{message?.model}</TableCell>

                              {message &&
                                  message.metrics &&
                                  message.metrics.map(
                                      (item: any, index: number) => {
                                          return (
                                              <TableCell>
                                                  <span
                                                      style={{
                                                          display: 'flex',
                                                          justifyContent:
                                                              'right',
                                                      }}
                                                  >
                                                      {item.value &&
                                                      index ==
                                                          message.metrics
                                                              .length -
                                                              1
                                                          ? Number(
                                                                item.value
                                                            ).toFixed(2)
                                                          : item.value}
                                                  </span>
                                              </TableCell>
                                          )
                                      }
                                  )}
                          </tr>
                      </>
                  )
              })
            : ''
    return (
        <OutputContainer>
            {isDiscoverCodeOutput && selectedOutputTab === 'python' ? (
                <OutputInnerContainer>
                    <pre
                        style={{
                            width: '100%',
                            padding: '1rem',
                        }}
                    >
                        <CodeBlock
                            theme={dracula}
                            text={
                                isSummarizationOutput
                                    ? commandSummarizationText
                                    : outputImageURL
                                    ? commantImageGenrationText
                                    : isTranslationOutput
                                    ? commandTranslationText
                                    : modelSubType ===
                                      ModelSubType.WithDataInputFile
                                    ? ChatQnAInputFileCommandText
                                    : commandText
                            }
                            language={'Python'}
                            showLineNumbers={false}
                        />
                    </pre>
                </OutputInnerContainer>
            ) : isDiscoverCodeOutput && selectedOutputTab === 'container' ? (
                <OutputInnerContainer>
                    <pre
                        style={{
                            width: '100%',
                            padding: '1rem',
                        }}
                    >
                        <OutputContainerTab>
                            <GetRAGButton href="https://optimizations.intel.com/public/catalog?uuid=opt-c1ab70f2-7913-4ac0-bfe3-8a35953861df">
                                Get RAG Solution Container
                            </GetRAGButton>
                        </OutputContainerTab>
                    </pre>
                </OutputInnerContainer>
            ) : (
                ''
            )}
            {isRetrievalOutput && (
                <OutputInnerContainer>
                    {/* Retrieval Output */}

                    <PreStyle>
                        <CodeBlock
                            theme={CodeblockCustomTheme}
                            text={
                                !isResetClicked &&
                                retrievalOutputData?.data[0]?.embedding
                                    ? `    [\n\t${retrievalOutputData?.data[0]?.embedding.join(
                                          ', \n\t'
                                      )}\n    ]`
                                    : ragModelExampleData
                            }
                            language="javascript"
                            showLineNumbers={false}
                        />
                    </PreStyle>
                    {retrievalQueryLoading === LoadingState.Pending &&
                        loadingSection}
                </OutputInnerContainer>
            )}
            {isCompareOutput && (
                <CompareInnerContainer>
                    {compareOutputData && compareOutputData.length > 0 ? (
                        <div
                            style={{
                                width: '100%',
                                padding: '1rem',
                            }}
                        >
                            <OutputContainerTab
                                style={{ flexDirection: 'column' }}
                            >
                                {ragDataLength && ragDataLength.length > 0 && (
                                    <h6 style={{ color: '#000' }}>
                                        RAG + LLM Model Output:
                                    </h6>
                                )}
                                <Table
                                    responsive
                                    style={{
                                        background: '#FFFFFF',
                                        border: '1px solid #B9B9B9',
                                        width: '100%',
                                    }}
                                >
                                    <tbody>
                                        <tr
                                            style={{
                                                background: '#AEAEAE',
                                            }}
                                        >
                                            {tableHeaders(
                                                compareOutputData &&
                                                    compareOutputData.filter(
                                                        (item: any) =>
                                                            item.isRagModel ===
                                                            true
                                                    )
                                            )}
                                        </tr>
                                        <tr></tr>
                                        {tableRows(
                                            compareOutputData &&
                                                compareOutputData.filter(
                                                    (item: any) =>
                                                        item.isRagModel === true
                                                )
                                        )}
                                    </tbody>
                                </Table>
                                <br />
                                <br />
                                <br />

                                <h6 style={{ color: '#000' }}>
                                    {' '}
                                    {compareOutputData.filter(
                                        (item: any) => item.isRagModel === false
                                    ).length > 0
                                        ? 'LLM Model Output:'
                                        : ''}{' '}
                                </h6>
                                <Table
                                    responsive
                                    style={{
                                        background: '#FFFFFF',
                                        border: '1px solid #B9B9B9',
                                        width: '100%',
                                    }}
                                >
                                    <tbody>
                                        <tr
                                            style={{
                                                background: '#AEAEAE',
                                            }}
                                        >
                                            {tableHeaders(
                                                compareOutputData &&
                                                    compareOutputData.filter(
                                                        (item: any) =>
                                                            item.isRagModel ===
                                                            false
                                                    )
                                            )}
                                        </tr>
                                        <tr></tr>
                                        {tableRows(
                                            compareOutputData &&
                                                compareOutputData.filter(
                                                    (item: any) =>
                                                        item.isRagModel ===
                                                        false
                                                )
                                        )}
                                    </tbody>
                                </Table>
                            </OutputContainerTab>
                        </div>
                    ) : (
                        ''
                    )}
                </CompareInnerContainer>
            )}

            {isSummarizationOutput && selectedOutputTab === 'output' && (
                <OutputInnerContainer>
                    {/* Retrieval Output */}

                    <pre
                        style={{
                            width: '100%',
                            padding: '1rem',
                            background: '#FFFFFF',
                            textAlign: 'justify',
                        }}
                    >
                        <OutputContainerTab>
                            <PreStyle>
                                {!isResetClicked &&
                                summarizationOutputData?.choices
                                    ? summarizationOutputData?.choices[0]
                                          .message.content
                                    : summarizationExampleData}
                            </PreStyle>
                        </OutputContainerTab>
                    </pre>
                    {summarizationOutputLoading !== LoadingState.Idle &&
                        loadingSection}
                </OutputInnerContainer>
            )}

            {isVisualOutput && selectedOutputTab === 'output' && (
                <OutputInnerContainer style={{ minHeight: '32rem' }}>
                    <OutputImageSpanContainer>
                        <img
                            src={outputImageURL}
                            height="100%"
                            width="100%"
                            style={{ objectFit: 'contain', inset: 0 }}
                        ></img>
                    </OutputImageSpanContainer>
                    {imageGenerationLoading === LoadingState.Pending &&
                        loadingSection}
                </OutputInnerContainer>
            )}
        </OutputContainer>
    )
}

export default OutputPanel
