import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ChatCompletionRequest } from '../models/chatCompletionRequest'
import { LoadingState } from '../models/loadingState'
import ChatCompletionService from '../services/chatCompletionService'
import { RetrievalModelRequest } from '../models/retrievalModelRequest'

const initialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

const chatInitialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
    addData: null,
}
export const getAIModels = createAsyncThunk(
    'getAIModels',
    async (qParam: any) => {
        return await ChatCompletionService.getAIModels(qParam)
    }
)

// Then, handle actions in your reducers:
const getAIModelsSlice = createSlice({
    name: 'getAIModels',
    initialState: initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
            .addCase(getAIModels.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getAIModels.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                    state.error = null
                }
            })
            .addCase(getAIModels.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const postChatCompletion = createAsyncThunk(
    'postChatCompletion',
    async (request: ChatCompletionRequest) => {
        return await ChatCompletionService.SubmitChatQuery(request)
    }
)

// Then, handle actions in your reducers:
const postChatCompletionSlice = createSlice({
    name: 'postChatCompletion',
    initialState: chatInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(postChatCompletion.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(postChatCompletion.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload?.data
                        ? action.payload?.data
                        : []
                    state.error = null
                }
            })
            .addCase(postChatCompletion.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const postRetrievalQuery = createAsyncThunk(
    'postRetrievalQuery',
    async (request: RetrievalModelRequest) => {
        return await ChatCompletionService.SubmitRetrievalQuery(request)
    }
)

// Then, handle actions in your reducers:
const postRetrievalQuerySlice = createSlice({
    name: 'postRetrievalQuery',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(postRetrievalQuery.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(postRetrievalQuery.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                    state.error = null
                }
            })
            .addCase(postRetrievalQuery.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getAIModelsResult = getAIModelsSlice.reducer
export const postChatCompletionResult = postChatCompletionSlice.reducer
export const postRetrievalQueryResult = postRetrievalQuerySlice.reducer
