/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import {
    FunctionComponent,
    Dispatch,
    useState,
    useCallback,
    FormEvent,
    useEffect,
    useRef,
    ChangeEvent,
} from 'react'
import { connect } from 'react-redux'
import { LoadingState } from '../models/loadingState'
import SideLinkPanel from '../layouts/sidePanel'
import AiModelHeaderPanel from '../layouts/header'
import AiModelCard from '../layouts/aiModelCard'
import AiModelFooterPanel from '../layouts/footer'
import { LayoutStylePreference } from '../models/layoutStyle'

import {
    getAIModels,
    postChatCompletion,
    postRetrievalQuery,
} from '../store/chatCompletion.slice'
import { ChatCompletionRequest } from '../models/chatCompletionRequest'

import '../components/emblaCarousel/css/embla.css'
import { setScrollIntoViewWithId } from '../lib/scroll'
import VisualModelPanel from '../components/visualModelPanel'
import { ModelSubType, ModelType } from '../models/modelType'
import SummarizationModelPanel from '../components/summarizationModelPanel'
import { SubmitImageGeneration } from '../store/imageGeneration.slice'
import { ImageGenerationRequest } from '../models/imageGenerationRequest'
import ImageGenerationModelPanel from '../components/ImageGenerationModelPanel'
import TranslationModelPanel from '../components/translationModelPanel'
import { RetrievalModelRequest } from '../models/retrievalModelRequest'
import RetrievalModelPanel from '../components/retrievalModelPanel'
import ChatModelPanel from '../components/chatModelPanel'
import { FormControl, InputGroup, Spinner, Alert } from 'react-bootstrap'
import { KeyboardEvent } from 'react'
import { genericErrorMessage } from '../models/error'
import AudioModelPanel from '../components/audioModelPanel'

const DiscoverPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (max-width: 500px) {
        width: 100%;
        height: 100%;
    }
    overflow-y: auto;
    overflow-x: hidden;
    background-color: rgb(24, 24, 24, 0.5);

    /* scroll style-width */
    ::-webkit-scrollbar {
        height: 1rem;
        width: 0.8rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        // background: #f1f1f1;
        // border-radius: 20px;
        background-color: transparent;
        border-radius: 9999px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        // background: #888;
        // border-radius: 20px;
        --tw-border-opacity: 1;
        background-color: hsla(0, 0%, 89%, 0.8);
        border-color: rgba(255, 255, 255, var(--tw-border-opacity));
        border-radius: 9999px;
        border-width: 1px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`

const DiscoverHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
    margin-top: 3.5rem;
`
const DiscoverBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    //justify-content: center;
    width: 100%;
    background-color: #fefefe;
    overflow-y: auto;
`
const SidePanelComponent = styled.div`
    display: block;
    @media screen and (max-width: 600px) {
        display: none;
    }
`

const MainSection = styled.section`
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 0;
    padding: 2rem 1rem 3rem 1rem;
    background-color: #e8e8e8; //rgb(12, 12, 12, 1);
    margin: 1rem 1.5rem;
`
const NextButton = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 2.25rem;
    height: 2.25rem;
    padding: 10px;
    gap: 10px;
    border-radius: 18px;
    cursor: pointer;
    tabindex: 0;
    border: 1px solid #00a3fb;
    &:hover {
        color: #00a3fb;
        border: 1px solid #00a3fb;
    }
    &:focus {
        color: #00a3fb;
        border: 1px solid #00a3fb;
        outline: auto;
    }
    &:disabled {
        color: #00a3fb;
        border: 1px solid transparent;
    }
`
const CardColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: '0.1rem';
`
const CardPanelContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
`
const SpinnerDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    width: 100%;
`
const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    justify-content: center;
    border-radius: 0;
    width: 95%;
`

const SearchComponent = styled(FormControl)`
    display: flex;
    border-top-right-radius: 0.7rem !important;
    border-bottom-right-radius: 0.7rem !important;
    font-size: 1.1rem !important;
`
const SearchGroupComponent = styled(InputGroup)`
    display: flex;
    border-radius: 0.5rem;
    min-height: 2vw;
    width: 42vw;
    @media screen and (max-width: 500px) {
        width: 40vw !important;
    }
    align-self: center;
`
const SearchIcon = styled(InputGroup.Text)`
    border-top-left-radius: 0.7rem !important;
    border-bottom-left-radius: 0.7rem !important;
`

export interface IDiscoverContainerProps {
    postChatQuery: typeof postChatCompletion
    chatCompletionAnswer: any
    chatCompletionLoading: LoadingState
    chatCompletionError: any
    getAIModels: typeof getAIModels
    getAIModelsResultData: any
    getAIModelsLoading: LoadingState
    getAIModelsError: any
    submitImageGeneration: typeof SubmitImageGeneration
    submitImageGenerationResult: any
    submitImageGenerationLoading: LoadingState
    submitImageGenerationError: any
    postRetrievalQuery: typeof postRetrievalQuery
    retrievalQueryAnswer: any
    retrievalQueryLoading: LoadingState
    retrievalQueryError: any
    SearchComponentRef?: any
}

const DiscoverContainer: FunctionComponent<IDiscoverContainerProps> & {
    defaultProps: Partial<IDiscoverContainerProps>
} = ({
    getAIModels,
    getAIModelsResultData,
    getAIModelsLoading,
    getAIModelsError,
    postChatQuery,
    chatCompletionAnswer,
    chatCompletionLoading,
    chatCompletionError,
    submitImageGeneration,
    submitImageGenerationResult,
    submitImageGenerationLoading,
    submitImageGenerationError,
    postRetrievalQuery,
    retrievalQueryAnswer,
    retrievalQueryLoading,
    retrievalQueryError,
}: IDiscoverContainerProps) => {
    const SearchComponentRef = useRef<any>()

    const [isNewModelLoad, setIsNewModelLoad] = useState<boolean>(false)
    const [trendingModelsList, setTrendingModelsList] = useState<any>()
    const [showModelDetailControl, setShowModelDetailControl] =
        useState<boolean>(false)
    const [moduleDisplayGroup, setModuleDisplayGroup] = useState<number>(1)
    const [selectedModelName, setSelectedModelName] = useState<string>('')
    const [selectedModelPublishedBy, setSelectedModelPublishedBy] =
        useState<string>('')
    const [currentPromptMessage, setCurrentPromptMessage] = useState<string>('')
    const [selectedCardDescription, setSelectedCardDescription] =
        useState<string>('')
    const [showVisualPanel, setShowVisualPanel] = useState<boolean>(false)
    const [showImageGenrationModel, setShowImageGenrationModel] =
        useState<boolean>(false)

    const [showSummarizationPanel, setShowSummarizationPanel] =
        useState<boolean>(false)
    const [showTranslatorPanel, setShowTranslatorPanel] =
        useState<boolean>(false)
    const [modelType, setModelType] = useState<ModelType>(ModelType.Trending)
    const [modelSubType, setModelSubType] = useState<ModelSubType>(
        ModelSubType.none
    )
    const [showRetrievalPanel, setShowRetrievalPanel] = useState<boolean>(false)
    const [showAudioPanel, setShowAudioPanel] = useState<boolean>(false)
    const [isStream, setIsStream] = useState<boolean>(false)

    useEffect(() => {
        getAIModels('?trending=true&count=50')
    }, [])

    const onAppNameChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLTextAreaElement
        if (target.value != undefined) {
            if (target.value === '') {
                //reset search banner and refresh data for default search
                setSearchURL()
            }
        }
    }, [])

    const setSearchURL = () => {
        if (SearchComponentRef.current !== undefined) {
            let searchUrl = `?search=${encodeURIComponent(
                SearchComponentRef?.current?.value.trim()
            )}&count=50`
            getAIModels(searchUrl)
        }
    }

    const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            setSearchURL()
        }
    }

    const onCardClick = useCallback(
        (
            event: FormEvent<HTMLElement>,
            modelName: string,
            displayName: string,
            publishedBy: string,
            isActive: boolean,
            type: ModelType,
            subType: ModelSubType,
            isStream: boolean
        ) => {
            if (isActive && type) {
                let cardDescription = `${displayName}  `
                setSelectedModelName(modelName)
                setSelectedModelPublishedBy(publishedBy)
                setSelectedCardDescription(cardDescription)
                setModelType(type)
                setModelSubType(subType)
                setIsStream(isStream)
                setIsNewModelLoad(true)
                if (
                    type == ModelType.Trending ||
                    subType == ModelSubType.WithDataInputFile
                )
                    setShowModelDetailControl(
                        (prevState: boolean) => !prevState
                    )
                else if (type == ModelType.Visual) {
                    if (subType == ModelSubType.textToImage) {
                        setShowImageGenrationModel(
                            (prevState: boolean) => !prevState
                        )
                    } else
                        setShowVisualPanel((prevState: boolean) => !prevState)
                } else if (type == ModelType.SummaryDoc) {
                    setShowSummarizationPanel(
                        (prevState: boolean) => !prevState
                    )
                } else if (type == ModelType.Translation)
                    setShowTranslatorPanel((prevState: boolean) => !prevState)
                else if (type == ModelType.Retrieval)
                    setShowRetrievalPanel((prevState: boolean) => !prevState)
                else if (type == ModelType.Audio)
                    setShowAudioPanel((prevState: boolean) => !prevState)
            }
        },
        [showModelDetailControl]
    )
    useEffect(() => {
        if (showModelDetailControl) {
            setScrollIntoViewWithId('scrollUpToId')
        }
    }, [showModelDetailControl])

    const cards = (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
                flexWrap: 'wrap',
            }}
        >
            {getAIModelsResultData &&
                getAIModelsResultData.models &&
                getAIModelsResultData.models.map((item: any, index: any) => (
                    <CardColumn key={`card-${index}`}>
                        <AiModelCard
                            imageURL={item.logo}
                            modelName={item.name}
                            displayName={item.displayName}
                            publishedBy={item.author}
                            description={item.description}
                            onCardClickCallBack={(e: any) =>
                                onCardClick(
                                    e,
                                    item.name,
                                    item.displayName,
                                    item.author,
                                    item.isActive,
                                    item?.tags['Use Case'],
                                    item?.tags['Subtype'],
                                    item.isStream
                                )
                            }
                            category={item?.tags['Feature']}
                            renderStyle={LayoutStylePreference.vStyle}
                            isActive={item.isActive}
                            index={index}
                        />
                    </CardColumn>
                ))}
        </div>
    )

    const trendingSection = (
        <div>
            <CardPanelContainer>{cards}</CardPanelContainer>
        </div>
    )

    return (
        <DiscoverPanelContainer>
            <AiModelHeaderPanel></AiModelHeaderPanel>
            <DiscoverHorizontalContainer>
                <SidePanelComponent>
                    <SideLinkPanel></SideLinkPanel>
                </SidePanelComponent>

                <DiscoverBodyContainer>
                    {getAIModelsError && (
                        <AlertComponent
                            variant="danger"
                            show={getAIModelsError ? true : false}
                        >
                            {genericErrorMessage}
                        </AlertComponent>
                    )}
                    {getAIModelsLoading !== LoadingState.Pending ? (
                        <MainSection style={{ marginTop: '1.6rem' }}>
                            <SearchGroupComponent size="lg">
                                <SearchIcon>
                                    <i className="uil uil-search"></i>
                                </SearchIcon>
                                <SearchComponent
                                    placeholder="Search Intel AI"
                                    onChange={onAppNameChange}
                                    onKeyDown={onKeyDown}
                                    required
                                    ref={SearchComponentRef}
                                    type="search"
                                    data-testid="search-textbox"
                                />
                            </SearchGroupComponent>
                            {trendingSection}

                            <ChatModelPanel
                                postChatQuery={postChatQuery}
                                chatCompletionAnswer={chatCompletionAnswer}
                                chatCompletionLoading={chatCompletionLoading}
                                chatCompletionError={chatCompletionError}
                                selectedModelName={selectedModelName}
                                selectedModelPublishedBy={
                                    selectedModelPublishedBy
                                }
                                showModel={showModelDetailControl}
                                setShowModel={setShowModelDetailControl}
                                isNewModelLoad={isNewModelLoad}
                                setIsNewModelLoad={setIsNewModelLoad}
                                modelType={modelType}
                                isStream={isStream}
                                modelSubType={modelSubType}
                            ></ChatModelPanel>
                            <VisualModelPanel
                                postChatQuery={postChatQuery}
                                chatCompletionAnswer={chatCompletionAnswer}
                                chatCompletionLoading={chatCompletionLoading}
                                chatCompletionError={chatCompletionError}
                                selectedModelName={selectedModelName}
                                selectedModelPublishedBy={
                                    selectedModelPublishedBy
                                }
                                showModel={showVisualPanel}
                                setShowModel={setShowVisualPanel}
                                isNewModelLoad={isNewModelLoad}
                                setIsNewModelLoad={setIsNewModelLoad}
                                isStream={isStream}
                            ></VisualModelPanel>
                            <ImageGenerationModelPanel
                                submitImageGeneration={submitImageGeneration}
                                submitImageGenerationResult={
                                    submitImageGenerationResult
                                }
                                submitImageGenerationLoading={
                                    submitImageGenerationLoading
                                }
                                submitImageGenerationError={
                                    submitImageGenerationError
                                }
                                selectedModelName={selectedModelName}
                                selectedModelPublishedBy={
                                    selectedModelPublishedBy
                                }
                                showModel={showImageGenrationModel}
                                setShowModel={setShowImageGenrationModel}
                                isNewModelLoad={isNewModelLoad}
                                setIsNewModelLoad={setIsNewModelLoad}
                                isStream={isStream}
                            ></ImageGenerationModelPanel>
                            <SummarizationModelPanel
                                postChatQuery={postChatQuery}
                                chatCompletionAnswer={chatCompletionAnswer}
                                chatCompletionLoading={chatCompletionLoading}
                                chatCompletionError={chatCompletionError}
                                selectedModelName={selectedModelName}
                                selectedModelPublishedBy={
                                    selectedModelPublishedBy
                                }
                                setShowModel={setShowSummarizationPanel}
                                showModel={showSummarizationPanel}
                                isStream={isStream}
                                isNewModelLoad={isNewModelLoad}
                                setIsNewModelLoad={setIsNewModelLoad}
                            />
                            <TranslationModelPanel
                                postChatQuery={postChatQuery}
                                chatCompletionAnswer={chatCompletionAnswer}
                                chatCompletionLoading={chatCompletionLoading}
                                chatCompletionError={chatCompletionError}
                                selectedModelName={selectedModelName}
                                selectedModelPublishedBy={
                                    selectedModelPublishedBy
                                }
                                setShowModel={setShowTranslatorPanel}
                                showModel={showTranslatorPanel}
                                isStream={isStream}
                                isNewModelLoad={isNewModelLoad}
                                setIsNewModelLoad={setIsNewModelLoad}
                            />
                            <RetrievalModelPanel
                                postRetrievalQuery={postRetrievalQuery}
                                retrievalQueryAnswer={retrievalQueryAnswer}
                                retrievalQueryLoading={retrievalQueryLoading}
                                retrievalQueryError={retrievalQueryError}
                                selectedModelName={selectedModelName}
                                selectedModelPublishedBy={
                                    selectedModelPublishedBy
                                }
                                showModel={showRetrievalPanel}
                                setShowModel={setShowRetrievalPanel}
                                isNewModelLoad={isNewModelLoad}
                                setIsNewModelLoad={setIsNewModelLoad}
                            ></RetrievalModelPanel>
                            <AudioModelPanel
                                postChatQuery={postChatQuery}
                                chatCompletionAnswer={chatCompletionAnswer}
                                chatCompletionLoading={chatCompletionLoading}
                                chatCompletionError={chatCompletionError}
                                selectedModelName={selectedModelName}
                                selectedModelPublishedBy={
                                    selectedModelPublishedBy
                                }
                                showModel={showAudioPanel}
                                setShowModel={setShowAudioPanel}
                                isNewModelLoad={isNewModelLoad}
                                setIsNewModelLoad={setIsNewModelLoad}
                                isStream={isStream}
                            ></AudioModelPanel>
                        </MainSection>
                    ) : (
                        <SpinnerDiv>
                            <Spinner animation="border" variant="light">
                                <span className="visually-hidden">
                                    Loading...
                                </span>
                            </Spinner>
                        </SpinnerDiv>
                    )}
                    <AiModelFooterPanel></AiModelFooterPanel>
                </DiscoverBodyContainer>
            </DiscoverHorizontalContainer>
        </DiscoverPanelContainer>
    )
}

DiscoverContainer.defaultProps = {}

//flags on the image without robot

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        getAIModels: (qParam: any) => dispatch(getAIModels(qParam)),
        postChatQuery: (question: ChatCompletionRequest) =>
            dispatch(postChatCompletion(question)),

        submitImageGeneration: (request: ImageGenerationRequest) =>
            dispatch(SubmitImageGeneration(request)),

        postRetrievalQuery: (request: RetrievalModelRequest) =>
            dispatch(postRetrievalQuery(request)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        getAIModelsResultData: state.getAIModelsResult.data,
        getAIModelsLoading: state.getAIModelsResult.loading,
        getAIModelsError: state.getAIModelsResult.error,

        chatCompletionAnswer: state.postChatCompletionResult.data,
        chatCompletionLoading: state.postChatCompletionResult.loading,
        chatCompletionError: state.postChatCompletionResult.error,

        submitImageGenerationResult: state.submitImageGenerationResult.data,
        submitImageGenerationLoading: state.submitImageGenerationResult.loading,
        submitImageGenerationError: state.submitImageGenerationResult.error,

        retrievalQueryAnswer: state.postRetrievalQueryResult.data,
        retrievalQueryLoading: state.postRetrievalQueryResult.loading,
        retrievalQueryError: state.postRetrievalQueryResult.error,
    }
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(DiscoverContainer)
