/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import {
    FunctionComponent,
    Dispatch,
    useState,
    useCallback,
    FormEvent,
    useEffect,
} from 'react'
import { connect } from 'react-redux'
import { LoadingState } from '../models/loadingState'
import SideLinkPanel from '../layouts/sidePanel'
import AiModelHeaderPanel from '../layouts/header'
import AiModelCard from '../layouts/aiModelCard'
import AiModelFooterPanel from '../layouts/footer'
import { LayoutStylePreference } from '../models/layoutStyle'
import { Alert, CloseButton, Modal, Spinner } from 'react-bootstrap'
import { getAIModels, postChatCompletion } from '../store/chatCompletion.slice'
import { ChatCompletionRequest } from '../models/chatCompletionRequest'
import '../components/emblaCarousel/css/embla.css'
import { setScrollIntoViewWithId } from '../lib/scroll'
import VisualModelPanel from '../components/visualModelPanel'
import { ModelSubType, ModelType } from '../models/modelType'
import ImageGenerationModelPanel from '../components/ImageGenerationModelPanel'
import { SubmitImageGeneration } from '../store/imageGeneration.slice'
import { ImageGenerationRequest } from '../models/imageGenerationRequest'
import { genericErrorMessage } from '../models/error'

const VisualPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (max-width: 500px) {
        width: 100%;
        height: 100%;
    }
    overflow-y: auto;
    overflow-x: hidden;
    background-color: rgb(24, 24, 24, 0.5);

    /* scroll style-width */
    ::-webkit-scrollbar {
        height: 1rem;
        width: 0.8rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        // background: #f1f1f1;
        // border-radius: 20px;
        background-color: transparent;
        border-radius: 9999px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        // background: #888;
        // border-radius: 20px;
        --tw-border-opacity: 1;
        background-color: hsla(0, 0%, 89%, 0.8);
        border-color: rgba(255, 255, 255, var(--tw-border-opacity));
        border-radius: 9999px;
        border-width: 1px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`

const VisualHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
    margin-top: 3.5rem;
`
const VisualBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    width: 100%;
    background-color: #fefefe;
    overflow-y: auto;
`
const SidePanelComponent = styled.div`
    display: block;
    @media screen and (max-width: 600px) {
        display: none;
    }
`

const MainSection = styled.section`
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 0;
    padding: 2rem 1rem 3rem 1rem;
    background-color: #e8e8e8; //rgb(12, 12, 12, 1);
    margin: 1rem 1.5rem;
`

const CardPanelContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
`
const SpinnerDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    width: 100%;
`
const AlertComponent = styled(Alert)`
    display: flex;
    margin: 1rem 2rem;
    justify-content: center;
    border-radius: 0;
    width: 95%;
`

export interface IVisualContainerProps {
    postChatQuery: typeof postChatCompletion
    chatCompletionAnswer: any
    chatCompletionLoading: LoadingState
    chatCompletionError: any
    getAIModels: typeof getAIModels
    getAIModelsResultData: any
    getAIModelsLoading: LoadingState
    getAIModelsError: any
    submitImageGeneration: typeof SubmitImageGeneration
    submitImageGenerationResult: any
    submitImageGenerationLoading: LoadingState
    submitImageGenerationError: any
    isStream: boolean
}

const VisualContainer: FunctionComponent<IVisualContainerProps> & {
    defaultProps: Partial<IVisualContainerProps>
} = ({
    getAIModels,
    getAIModelsResultData,
    getAIModelsLoading,
    getAIModelsError,
    postChatQuery,
    chatCompletionAnswer,
    chatCompletionLoading,
    chatCompletionError,
    submitImageGeneration,
    submitImageGenerationResult,
    submitImageGenerationLoading,
    submitImageGenerationError,
    isStream,
}: IVisualContainerProps) => {
    const [isNewModelLoad, setIsNewModelLoad] = useState<boolean>(false)
    const [showModelDetailControl, setShowModelDetailControl] =
        useState<boolean>(false)
    const [selectedModelName, setSelectedModelName] = useState<string>('')
    const [selectedModelPublishedBy, setSelectedModelPublishedBy] =
        useState<string>('')
    const [modelSubType, setModelSubType] = useState<ModelSubType>(
        ModelSubType.imageToText
    )

    useEffect(() => {
        getAIModels('?use-case=visual&count=50')
    }, [])

    const headingText = 'Visual Question and Answering'

    const onCardClick = useCallback(
        (
            event: FormEvent<HTMLElement>,
            modelName: string,
            publishedBy: string,
            isActive: boolean,
            subType: ModelSubType
        ) => {
            let count = 0
            if (isActive) {
                setSelectedModelName(modelName)
                setSelectedModelPublishedBy(publishedBy)
                setShowModelDetailControl((prevState) => !prevState)
                setIsNewModelLoad(true)
                if (subType == ModelSubType.textToImage)
                    setModelSubType(ModelSubType.textToImage)
                else setModelSubType(ModelSubType.imageToText)
            }
        },
        [showModelDetailControl]
    )
    useEffect(() => {
        if (showModelDetailControl) {
            setScrollIntoViewWithId('scrollUpToId')
        }
    }, [showModelDetailControl])

    const cards = (
        <div
            style={{
                display: 'flex',
                justifyContent: 'left',
                flexDirection: 'row',
                flexWrap: 'wrap',
            }}
        >
            {getAIModelsResultData &&
                getAIModelsResultData.models &&
                getAIModelsResultData.models.map((item: any, index: number) => {
                    return (
                        <AiModelCard
                            imageURL={item.logo}
                            modelName={item.name}
                            displayName={item.displayName}
                            publishedBy={item.author}
                            description={item.description}
                            onCardClickCallBack={(e: any) =>
                                onCardClick(
                                    e,
                                    item.name,
                                    item.author,
                                    item.isActive,
                                    item?.tags['Subtype']
                                )
                            }
                            category={item?.tags['Feature']}
                            renderStyle={LayoutStylePreference.vStyle}
                            isActive={item.isActive}
                            showCheckboxPanel={item.showCheckboxPanel}
                            isChecked={item.isChecked}
                            index={index}
                        />
                    )
                })}
        </div>
    )

    const visualSection = (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '1.5rem',
                }}
            >
                <h6>{headingText}</h6>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                }}
            >
                <CardPanelContainer>{cards}</CardPanelContainer>
            </div>
        </div>
    )

    return (
        <VisualPanelContainer>
            <AiModelHeaderPanel></AiModelHeaderPanel>
            <VisualHorizontalContainer>
                <SidePanelComponent>
                    <SideLinkPanel></SideLinkPanel>
                </SidePanelComponent>
                <VisualBodyContainer>
                    {getAIModelsError && (
                        <AlertComponent
                            variant="danger"
                            show={getAIModelsError ? true : false}
                        >
                            {genericErrorMessage}
                        </AlertComponent>
                    )}
                    {getAIModelsLoading !== LoadingState.Pending ? (
                        <MainSection style={{ marginTop: '1.6rem' }}>
                            {visualSection}{' '}
                            {modelSubType == ModelSubType.imageToText ? (
                                <VisualModelPanel
                                    postChatQuery={postChatQuery}
                                    chatCompletionAnswer={chatCompletionAnswer}
                                    chatCompletionLoading={
                                        chatCompletionLoading
                                    }
                                    chatCompletionError={chatCompletionError}
                                    selectedModelName={selectedModelName}
                                    selectedModelPublishedBy={
                                        selectedModelPublishedBy
                                    }
                                    showModel={showModelDetailControl}
                                    setShowModel={setShowModelDetailControl}
                                    isNewModelLoad={isNewModelLoad}
                                    setIsNewModelLoad={setIsNewModelLoad}
                                    isStream={isStream}
                                ></VisualModelPanel>
                            ) : modelSubType == ModelSubType.textToImage ? (
                                <ImageGenerationModelPanel
                                    submitImageGeneration={
                                        submitImageGeneration
                                    }
                                    submitImageGenerationResult={
                                        submitImageGenerationResult
                                    }
                                    submitImageGenerationLoading={
                                        submitImageGenerationLoading
                                    }
                                    submitImageGenerationError={
                                        submitImageGenerationError
                                    }
                                    selectedModelName={selectedModelName}
                                    selectedModelPublishedBy={
                                        selectedModelPublishedBy
                                    }
                                    showModel={showModelDetailControl}
                                    setShowModel={setShowModelDetailControl}
                                    isNewModelLoad={isNewModelLoad}
                                    setIsNewModelLoad={setIsNewModelLoad}
                                    isStream={isStream}
                                ></ImageGenerationModelPanel>
                            ) : (
                                ''
                            )}
                        </MainSection>
                    ) : (
                        <SpinnerDiv>
                            <Spinner animation="border" variant="light">
                                <span className="visually-hidden">
                                    Loading...
                                </span>
                            </Spinner>
                        </SpinnerDiv>
                    )}

                    <AiModelFooterPanel></AiModelFooterPanel>
                </VisualBodyContainer>
            </VisualHorizontalContainer>
        </VisualPanelContainer>
    )
}

VisualContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        getAIModels: (qParam: any) => dispatch(getAIModels(qParam)),
        postChatQuery: (question: ChatCompletionRequest) =>
            dispatch(postChatCompletion(question)),

        submitImageGeneration: (request: ImageGenerationRequest) =>
            dispatch(SubmitImageGeneration(request)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        getAIModelsResultData: state.getAIModelsResult.data,
        getAIModelsLoading: state.getAIModelsResult.loading,
        getAIModelsError: state.getAIModelsResult.error,

        chatCompletionAnswer: state.postChatCompletionResult.data,
        chatCompletionLoading: state.postChatCompletionResult.loading,
        chatCompletionError: state.postChatCompletionResult.error,

        submitImageGenerationResult: state.submitImageGenerationResult.data,
        submitImageGenerationLoading: state.submitImageGenerationResult.loading,
        submitImageGenerationError: state.submitImageGenerationResult.error,
    }
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(VisualContainer)
