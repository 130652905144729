/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import {
    ChangeEvent,
    FormEvent,
    FunctionComponent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import styled from 'styled-components'
import { LoadingState } from '../models/loadingState'
import {
    Button,
    Form,
    FormLabel,
    OverlayTrigger,
    Popover,
    Spinner,
} from 'react-bootstrap'
import StringUtils from '../lib/stringUtils'
import { KeyboardEvent } from 'react'
import { postChatCompletion } from '../store/chatCompletion.slice'
import {
    AudioMessage,
    ChatCompletionRequest,
    ChatMessage,
    VisualMessage,
} from '../models/chatCompletionRequest'
import { setScrollIntoViewWithId } from '../lib/scroll'
import { API_SERVICE_URL } from '../config/service'
import {
    EventStreamContentType,
    fetchEventSource,
} from '@microsoft/fetch-event-source'
import { ModelSubType, ModelType } from '../models/modelType'
import { visualSupportedFiles } from './code/visualChatPythonCode'
import mammoth from 'mammoth'
import * as pdfjslib from 'pdfjs-dist'
import { audioSupportedFiles } from './code/audioPythonCode'

pdfjslib.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url
).toString()

const serviceConfig = require('../config/service.json')

const ChatContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    border: 1px solid rgb(101, 49, 113);
    border-radius: 0px;
    padding: 1rem 0;
`
const MessageScrollContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    position: relative;
    min-height: 25rem;
    max-height: 25rem;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1rem;
`
const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    alignitems: center;
    height: 100%;
    width: 100%;
    margin-top: 1rem;
    @media screen and (max-width: 500px) {
        width: 100%;
    }
`
const InputContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

const MessageWrapper = styled.div`
    display: grid;
    grid-template-columns: 60px auto;
    //min-height: 100px;
    padding: 15px;
    margin: 1rem;
    //align-items: center;
    border-radius: 0;
    background-color: #fff;
    max-width: 95%;
    @media screen and (max-width: 500px) {
        width: 50%;
    }
`
const MessageTextContainer = styled.div`
    @media screen and (max-width: 500px) {
        width: 300px;
        paddingright: 0.5rem;
    }
`
const InputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;    
    overflow: hidden;
    height: 55px;
    background-color: #fff;
    border 1px solid rgba(0, 0, 0, .15);   
    border-radius: 1rem; 
    margin-bottom:1rem;
    width:90%;
    margin-left:1.5rem;
    @media screen and (max-width: 500px) {
        width: 90%;
    }
   
}
`
const InputText = styled(Form.Control)`   
    height: 52px;
    overflow-y: hidden;
    padding-right: 3rem;
    padding-left: 1rem;
    padding-bottom: 0.6rem;
    padding-top: 0.6rem;
    background-color: transparent;    
    resize: none;
    width: 100%;
    margin: 0;
    border:none;   
    border-width: 0;   
    appearance: none;
    font-size: 1rem;
    line-height: 1.5rem;
    &:focus {
        border:none;   
        border-width: 0;
        border-radius:0;   
        outline: none;       
        background-color: transparent;
        border: 0px none transparent;
    }
    &:hover {
        border:none;   
        border-width: 0;  
        border-radius:0; 
    }
}
`
const GoButton = styled(Button)`
    border: none;
    outline: none;
    cursor: pointer;
    transition-duration: 0.15s;
    transition-property: color, background-color, border-color,
        text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 1px;
    border-radius: 0.5rem;
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    right: 0.75rem;
    bottom: 0.75rem;
    //position: absolute;
    text-transform: none;
    background-color: transparent;

    &:focus {
        outline: none;
        background-color: transparent;
        border: 0px none transparent;
    }
    &:hover {
        outline: none;
        cursor: pointer;
        background-color: transparent;
    }
    &:disabled {
        opacity: 0.1;
        --tw-text-opacity: 1;
        color: rgba(180, 180, 180, var(--tw-text-opacity));
    }
`
const MessageTextP = styled.p`
    display: flex;
    flex-wrap: wrap;

    font-family: 'IntelOne Text';
    font-size: 1rem;
    font-weight: 400;
    @media screen and (max-width: 500px) {
        width: 300px;
    }
`

const UploadWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`
const UploadButton = styled(Button)`
    border: none;
    outline: none;
    cursor: pointer;
    //background-color: transparent;
    background: white;
    margin: 0.5rem;

    &:focus {
        outline: none;
        background: white;
        border: 0px none transparent;
    }
    &:hover {
        outline: none;
        cursor: pointer;
        background: white;
    }
    &:click {
        outline: none;
        cursor: pointer;
        background: white;
    }
    &:disabled {
        opacity: 0.1;
        color: rgba(180, 180, 180, var(--tw-text-opacity));
    }
`
const TextLabel = styled(FormLabel)`
    display: flex;
    color: var(--input-label-light-default, #494b51);
    font-family: 'IntelOne Display';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    padding-left: 0.3rem;
`
const PopoverKeyDiv = styled.div`
    background-color: #000;
    color: #fff;
    font-size: 1rem;
    padding: 0.5rem;
`

export interface IChatCompletionProps {
    postChatQuery: typeof postChatCompletion
    chatCompletionAnswer: any
    chatCompletionLoading: LoadingState
    chatCompletionError: any
    selectedModelName: string
    selectedModelPublishedBy: string
    CurrentPromptMessage: string
    setCurrentPromptMessage: any
    isStream: boolean
    isCompare?: boolean
    compareList?: any
    compareResult?: any
    setCompareResult?: any
    isNewModelLoad?: boolean
    setIsNewModelLoad?: any
    modelType?: ModelType
    setShowValidationError?: any
    modelSubType?: ModelSubType
}
export const UserImage = (userIntitials: string) => {
    return (
        <div style={{ paddingTop: '0.2rem' }}>
            <span
                style={{
                    height: '22px',
                    width: '22px',
                    background: '#653171',
                    borderRadius: '100%',
                    color: '#fff',
                    padding: '0.3rem',
                    fontWeight: '500',
                }}
            >
                {userIntitials}
            </span>
        </div>
    )
}
const ChatCompletionPanel: FunctionComponent<IChatCompletionProps> = ({
    postChatQuery,
    chatCompletionAnswer,
    chatCompletionLoading,
    chatCompletionError,
    selectedModelName,
    selectedModelPublishedBy,
    CurrentPromptMessage,
    setCurrentPromptMessage,
    isStream,
    isCompare,
    compareList,
    compareResult,
    setCompareResult,
    isNewModelLoad,
    setIsNewModelLoad,
    modelType,
    modelSubType,
    setShowValidationError,
}: IChatCompletionProps) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const inputFile = useRef<HTMLInputElement>(null)
    const [chatStreamCompletionLoading, setChatStreamCompletionLoading] =
        useState<string>(LoadingState.Idle)
    const [inputText, setInputText] = useState('')
    const [compareAPICallIndex, setCompareAPICallIndex] = useState<number>(0)
    const [imageFileBase64, setImageFileBase64] = useState<string>('')
    const [imageType, setImageType] = useState<string>('')
    const [imageFileURLs, setImageFileURLs] = useState<string[]>([])
    const [audioFileBase64, setAudioFileBase64] = useState<string>('')
    const [audioFileType, setAudioFileType] = useState<string>('')
    const [contextFileContent, setContextFileContent] = useState('')
    const [errorFile, setErrorFile] = useState<string | undefined>(undefined)
    const initialMessage =
        modelType == ModelType.Visual
            ? 'Select the image first then ask a question about image.'
            : modelType == ModelType.Audio
            ? 'Select an audio file first then ask a question about it.'
            : 'Ask a question.'

    const [messages, setMessages] = useState<
        {
            role: string
            content: string
            model?: string
            type?: string
            metric?: string[]
            isRagModel?: boolean
            blobURL?: string
        }[]
    >([
        {
            role: 'assistant',
            content: initialMessage,
        },
    ])
    const supportedContextFiles = [
        'text/plain',
        '.doc',
        '.docx',
        '.pdf',
        'application/pdf',
    ]

    const getVisualRequestMessage = () => {
        const visualMsg = new VisualMessage()
        visualMsg.role = 'user'
        visualMsg.content = [
            { type: 'text', text: inputText },
            {
                type: 'image_url',
                image_url: {
                    url: `data:${imageType};base64, ${imageFileBase64}`,
                },
            },
        ]
        return visualMsg
    }
    const getAudioRequestMessage = () => {
        const audioMsg = new AudioMessage()
        audioMsg.role = 'user'
        audioMsg.content = [
            { type: 'text', text: inputText },
            {
                type: 'audio_url',
                audio_url: {
                    url: `data:${audioFileType};base64, ${audioFileBase64}`,
                },
            },
        ]
        return audioMsg
    }

    const handleSubmit = async () => {
        const prompt = {
            role: 'user',
            content: inputText,
        }
        if (inputText.trim() != '') {
            setMessages([...messages, prompt])
            setCurrentPromptMessage(inputText)
            setTimeout(
                () => setScrollIntoViewWithId(`message${messages.length + 1}`),
                100
            )
            if (isCompare) {
                setCompareAPICallIndex(0)
                setCompareResult([])
            }
            //get answer
            SubmitQueryForAnswer(selectedModelName)
            setIsNewModelLoad(false)
        }
    }
    const SubmitQueryForAnswer = (model: string, listCountIndex?: number) => {
        const request = new ChatCompletionRequest()
        if (
            contextFileContent &&
            modelSubType == ModelSubType.WithDataInputFile
        ) {
            const msg1 = new ChatMessage()
            msg1.role = 'system'
            msg1.content = contextFileContent
            const msg2 = new ChatMessage()
            msg2.role = 'user'
            msg2.content = inputText
            request.messages = [msg1, msg2]
        } else {
            request.messages = inputText
        }
        request.model = model
        request.temperature = 0.5
        request.top_p = 1
        request.max_tokens = 512
        if (isStream) {
            request.stream = true
            setChatStreamCompletionLoading(LoadingState.Pending)
            if (modelType == ModelType.Visual) {
                request.messages = [getVisualRequestMessage()]
            }
            if (modelType == ModelType.Audio) {
                request.messages = [getAudioRequestMessage()]
            }
            GetStreamChatQueryAnswer(request)
        } else if (isCompare && compareList && compareList.length > 0) {
            request.stream = false
            if (
                listCountIndex &&
                listCountIndex > 0 &&
                listCountIndex < compareList.length
            ) {
                request.model = compareList[listCountIndex].name
                setCompareAPICallIndex(listCountIndex + 1)
                postChatQuery(request)
            } else {
                request.model = compareList[0].name
                setCompareAPICallIndex(1)
                postChatQuery(request)
            }
        } else {
            request.stream = false
            if (modelType == ModelType.Visual) {
                request.messages = [getVisualRequestMessage()]
            }
            if (modelType == ModelType.Audio) {
                request.messages = [getAudioRequestMessage()]
            }
            postChatQuery(request)
        }
    }

    const GetStreamChatQueryAnswer = useCallback(
        async (request: ChatCompletionRequest) => {
            var url = API_SERVICE_URL + serviceConfig.aiModelChatCompletion
            let eventData: any
            let concatMessages: string = ''
            const ctrl = new AbortController()
            setMessages((msg) => [
                ...msg,
                {
                    role: 'assistant',
                    content: '...',
                },
            ])
            try {
                await fetchEventSource(url, {
                    method: 'POST',
                    headers: {},
                    body: JSON.stringify(request),
                    signal: ctrl.signal,
                    openWhenHidden: true,
                    onopen: async (res) => {
                        const contentType = res.headers.get('content-type')
                        if (res.ok && contentType === EventStreamContentType) {
                            return // everything's good
                        } else if (
                            res.status >= 400 &&
                            res.status < 500 &&
                            res.status !== 429
                        ) {
                            // client-side errors are usually non-retriable:
                            throw await res.json()
                        }
                    },
                    onmessage(event) {
                        const data = event.data
                        if (!data) {
                            return
                        }
                        if (data.includes('DONE')) {
                            setChatStreamCompletionLoading(LoadingState.Idle)
                            return
                        }
                        try {
                            eventData = JSON.parse(data)
                            //remove old concat
                            setMessages((msg) => msg.slice(0, -1))

                            let content = eventData?.choices
                                ? eventData?.choices[0].message.content
                                : []
                            concatMessages = concatMessages + content

                            setMessages((msg) => [
                                ...msg,
                                {
                                    role: 'assistant',
                                    content: concatMessages,
                                },
                            ])

                            setInputText('')
                            if (
                                inputRef &&
                                inputRef.current !== undefined &&
                                inputRef.current !== null
                            )
                                inputRef.current.value = ''

                            //add smooth scroll
                            setTimeout(
                                () =>
                                    setScrollIntoViewWithId(
                                        `message${messages.length + 1}`
                                    ),
                                3000
                            )
                        } catch (e) {
                            console.log(e)
                            setChatStreamCompletionLoading(LoadingState.Idle)
                        }
                    },
                    onclose() {
                        // if the server closes the connection unexpectedly, retry:
                        console.log('connection closed')
                        setChatStreamCompletionLoading(LoadingState.Idle)
                    },
                    onerror(err) {
                        //ctrl.abort()
                        setChatStreamCompletionLoading(LoadingState.Idle)
                        throw err
                    },
                })
            } catch (e) {
                console.log('Error', e)
            }
        },
        [
            setChatStreamCompletionLoading,
            setMessages,
            messages,
            setInputText,
            setTimeout,
            setScrollIntoViewWithId,
        ]
    )
    useEffect(() => {
        if (
            chatCompletionAnswer &&
            !isStream &&
            chatCompletionLoading !== LoadingState.Pending
        ) {
            if (
                isCompare &&
                compareList &&
                compareList.length > 0 &&
                compareAPICallIndex <= compareList.length
            ) {
                //alert(compareAPICallIndex + ',' + compareList.length) //Remove
                if (isNewModelLoad) {
                    setMessages([
                        {
                            role: 'assistant',
                            content: initialMessage,
                        },
                    ])
                    setCompareResult([])
                } else {
                    setMessages((messages) => [
                        ...messages,
                        {
                            role: 'assistant',
                            content:
                                chatCompletionAnswer?.model +
                                ': <b>' +
                                chatCompletionAnswer?.choices[0]?.message
                                    .content,
                            model: chatCompletionAnswer?.model,
                            type: 'compare',
                            metrics: chatCompletionAnswer?.metrics,
                            isRagModel:
                                chatCompletionAnswer?.model &&
                                chatCompletionAnswer?.model.startsWith('rag-')
                                    ? true
                                    : false,
                        },
                    ])
                }
            } else if (!isCompare) {
                if (isNewModelLoad) {
                    setMessages([
                        {
                            role: 'assistant',
                            content: initialMessage,
                        },
                    ])
                } else {
                    setMessages((messages) => [
                        ...messages,
                        {
                            role: 'assistant',
                            content:
                                chatCompletionAnswer?.choices[0]?.message
                                    .content,
                        },
                    ])
                }
            }
            if (
                inputRef &&
                inputRef.current !== undefined &&
                inputRef.current !== null
            )
                inputRef.current.value = ''

            //add smooth scroll
            setTimeout(
                () => setScrollIntoViewWithId(`message${messages.length + 1}`),
                1000
            )
            if (
                isCompare &&
                compareList &&
                compareList.length > 1 &&
                compareAPICallIndex &&
                compareAPICallIndex < compareList.length
            ) {
                SubmitQueryForAnswer(
                    compareList[compareAPICallIndex].name,
                    compareAPICallIndex
                )
            } else {
                setInputText('')
            }
        }
    }, [chatCompletionAnswer, compareAPICallIndex, isNewModelLoad])

    useEffect(() => {
        if (isCompare && compareList && compareList.length > 0) {
            setCompareResult(
                messages
                    .filter((msg) => msg.type === 'compare')
                    .slice(-compareList.length)
            )
        }
    }, [messages])

    const onInputTextChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLTextAreaElement
        setInputText(target.value)
    }, [])
    const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            handleSubmit()
        }
    }
    const renderNewLine = (text: string, url?: string) => {
        if (text === 'image') {
            return (
                <span>
                    <img src={url} height="100%" width="100%"></img>
                </span>
            )
        } else if (text === 'audio') {
            return (
                <span>
                    <audio src={url} controls controlsList="nodownload"></audio>
                </span>
            )
        } else if (text && text.indexOf('<b>') > -1) {
            let lines = text.split('<b>')
            return (
                <div>
                    {lines.map((textLine: string, index: number) => {
                        if (index == 0)
                            return (
                                <div>
                                    <b>{textLine}</b>
                                    <br />
                                </div>
                            )
                        else return <div>{textLine}</div>
                    })}
                </div>
            )
        } else if (text && text.indexOf('\n') > -1) {
            let lines = text.split('\n')
            return (
                <div>
                    {lines.map((textLine: string) => {
                        return (
                            <div>
                                {textLine}
                                <br />
                            </div>
                        )
                    })}
                </div>
            )
        } else if (text && text.indexOf('<br/>') > -1) {
            let lines = text.split('<br/>')
            return (
                <div>
                    {lines.map((textLine: string) => {
                        return (
                            <div>
                                {textLine}
                                <br />
                            </div>
                        )
                    })}
                </div>
            )
        } else return text
    }

    const renderMessage = (
        role: string,
        content: string,
        index: number,
        totalMessageLength: number,
        blobURL: string
    ) => {
        return (
            <>
                <MessageWrapper key={index}>
                    <div>
                        <div id={`message${index + 1}`}>
                            {role === 'assistant' ? (
                                <img
                                    key={`role-image${index}`}
                                    src={StringUtils.resolve('/chatbot.png')}
                                    style={{ width: '40px', height: '35px' }}
                                    alt="profile avatar"
                                />
                            ) : (
                                UserImage(userIntitials)
                            )}
                        </div>
                    </div>
                    <MessageTextContainer key={`content-index-${index}`}>
                        <MessageTextP>
                            {blobURL !== ''
                                ? renderNewLine(content, blobURL)
                                : renderNewLine(content)}
                        </MessageTextP>
                    </MessageTextContainer>
                </MessageWrapper>
            </>
        )
    }
    let userIntitials = 'You'

    const handleUpload = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        if (inputFile && inputFile.current) {
            inputFile.current.click()
        }
    }, [])
    const endcodeFileBase64 = (file: any) => {
        const reader = new FileReader()
        if (file) {
            reader.readAsDataURL(file)
            reader.onload = () => {
                let Base64 = reader.result as string
                let Base64Value = Base64 ? Base64.split(',') : ''
                if (Base64Value) {
                    if (modelType === ModelType.Visual)
                        setImageFileBase64(Base64Value[1])
                    else if (modelType === ModelType.Audio)
                        setAudioFileBase64(Base64Value[1])
                }
            }
            reader.onerror = (error) => {
                console.log('base64Error: ', error)
            }
        }
    }

    const onFileChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLInputElement

            //-free resource before adding new
            if (imageFileURLs && imageFileURLs.length > 0) {
                imageFileURLs.map((url: any) => {
                    URL.revokeObjectURL(url)
                })
            }
            if (target.files && target?.files[0]) {
                setIsNewModelLoad(false)
                if (
                    modelType == ModelType.Visual &&
                    !visualSupportedFiles.includes(target?.files[0]?.type)
                ) {
                    setShowValidationError(
                        `Selected file type - ${target.files[0]?.type} is not supported.`
                    )
                } else if (
                    modelType == ModelType.Audio &&
                    !audioSupportedFiles.includes(target?.files[0]?.type)
                ) {
                    setShowValidationError(
                        `Selected file type - ${target.files[0]?.type} is not supported.`
                    )
                } else {
                    let contentType = 'image'
                    if (modelType === ModelType.Visual) {
                        setImageType(target.files[0].type)
                    } else if (modelType === ModelType.Audio) {
                        contentType = 'audio'
                        setAudioFileType(target.files[0].type)
                    }
                    setShowValidationError('')
                    const blob = new Blob([target.files[0]], {
                        type: `${target?.files[0].type}`,
                    })
                    const fileBlogURL = URL.createObjectURL(blob)
                    endcodeFileBase64(target.files[0])
                    setImageFileURLs([...imageFileURLs, fileBlogURL])
                    if (inputRef.current) {
                        inputRef.current.disabled = false
                        inputRef.current.focus()
                    }
                    const prompt = {
                        role: 'user',
                        content: contentType,
                        blobURL: fileBlogURL,
                    }
                    setMessages([...messages, prompt])
                }
            }
        },
        [messages, imageFileURLs]
    )

    const disableControl =
        (modelType === ModelType.Visual && imageFileBase64) ||
        (modelType === ModelType.Audio && audioFileBase64)
            ? false
            : !(modelType == ModelType.Visual || modelType == ModelType.Audio)
            ? false
            : true

    const onContextFileChange = async (file: File) => {
        let fileReader = new FileReader()
        fileReader.onloadend = async (e) => {
            const content = fileReader.result
            if (content && file.type == 'text/plain') {
                setContextFileContent(content.toString())
            } else if (file.type == 'application/pdf') {
                const pdfLoading = pdfjslib.getDocument(
                    e.target?.result as ArrayBuffer
                )
                const pdfDoc = await pdfLoading.promise
                let textContent = ''
                const totalPages = pdfDoc.numPages

                for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
                    const page = pdfDoc.getPage(pageNum)
                    const pageContent = (await page).getTextContent()
                    ;(await pageContent).items.forEach(
                        (item: any) => (textContent += `${item.str} `)
                    )
                }
                setContextFileContent(textContent)
            } else if (e.target?.result) {
                try {
                    const textFromDocument = await mammoth.extractRawText({
                        arrayBuffer: e.target?.result as ArrayBuffer,
                    })
                    setContextFileContent(textFromDocument.value)
                } catch (error) {
                    console.log(error)
                    setErrorFile('Error reading .docx file')
                }
            }
        }
        if (file.type == 'text/plain') {
            fileReader.readAsText(file)
        } else {
            fileReader.readAsArrayBuffer(file)
        }
    }

    return (
        <ChatContainer>
            <MessageScrollContainer>
                <Container>
                    <div style={{ height: '100%' }}>
                        {messages && messages.length > 0 ? (
                            messages.map((el, index) => {
                                return renderMessage(
                                    el.role,
                                    el.content,
                                    index,
                                    messages.length,
                                    el.blobURL ? el.blobURL : ''
                                )
                            })
                        ) : (
                            <div>'How can I help you today' </div>
                        )}
                    </div>
                </Container>
            </MessageScrollContainer>

            <InputContainer>
                <Container
                    style={{
                        justifyContent: 'left',
                    }}
                >
                    {chatStreamCompletionLoading === LoadingState.Pending ||
                    chatCompletionLoading === LoadingState.Pending ? (
                        <div style={{ marginLeft: '1.5rem' }}>
                            <Spinner
                                animation="grow"
                                variant="info"
                                size="sm"
                            />
                            <Spinner
                                animation="grow"
                                variant="info"
                                size="sm"
                            />
                            <Spinner
                                animation="grow"
                                variant="info"
                                size="sm"
                            />
                            <span> Working on it...</span>
                        </div>
                    ) : (
                        ''
                    )}
                </Container>
                <Container>
                    {modelType === ModelType.Visual ||
                    modelType === ModelType.Audio ? (
                        <>
                            <input
                                type="file"
                                id="file"
                                ref={inputFile}
                                onChange={onFileChange}
                                accept={
                                    modelType === ModelType.Visual
                                        ? 'image/*'
                                        : 'audio/*'
                                }
                                style={{ display: 'none' }}
                            />
                            <UploadWrapper>
                                <UploadButton onClick={handleUpload}>
                                    <span>
                                        <label
                                            style={{
                                                color: '#000',
                                                paddingRight: '1rem',
                                            }}
                                        >
                                            {modelType === ModelType.Visual
                                                ? 'Select image'
                                                : 'Select Audio File'}
                                        </label>
                                        <img
                                            src="/upload.svg"
                                            height="24"
                                            width="24"
                                            alt="slect file image"
                                        />
                                    </span>
                                </UploadButton>
                            </UploadWrapper>
                        </>
                    ) : modelSubType == ModelSubType.WithDataInputFile ? (
                        <div style={{ padding: '1rem 1.8rem' }}>
                            <TextLabel>
                                Add data
                                <OverlayTrigger
                                    placement={'right'}
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Popover
                                            id="pop_timeline"
                                            style={{
                                                maxWidth: '20%',
                                            }}
                                        >
                                            <Popover.Body>
                                                <PopoverKeyDiv>
                                                    Select a document you want
                                                    to add into the query
                                                    context
                                                </PopoverKeyDiv>
                                            </Popover.Body>
                                        </Popover>
                                    }
                                >
                                    <i
                                        className="uil uil-info-circle pointer"
                                        tabIndex={0}
                                    ></i>
                                </OverlayTrigger>
                            </TextLabel>
                            <Form.Group>
                                <Form.Control
                                    type="file"
                                    id={`file`}
                                    name={`file`}
                                    onChange={(
                                        event: ChangeEvent<HTMLElement>
                                    ) => {
                                        const target =
                                            event.currentTarget as HTMLInputElement

                                        if (
                                            target?.files &&
                                            target?.files[0] &&
                                            supportedContextFiles.includes(
                                                target?.files[0].type
                                            )
                                        ) {
                                            onContextFileChange(
                                                target?.files[0]
                                            )
                                        } else if (
                                            target?.files &&
                                            target?.files[0]
                                        ) {
                                            target.checkValidity()
                                            setErrorFile(
                                                `${target.files[0].type} is not supported.`
                                            )
                                        }
                                    }}
                                    key={`file`}
                                    data-testid={`file-value`}
                                    accept="text/plain, .doc, .docx, .pdf"
                                />
                                <Form.Control.Feedback
                                    type="invalid"
                                    data-validity={errorFile ? true : false}
                                >
                                    {errorFile}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                    ) : (
                        ''
                    )}
                    <InputWrapper>
                        <InputText
                            id="prompt-textarea"
                            rows={1}
                            placeholder="Enter your question and press Enter…"
                            //autoFocus
                            required
                            onChange={onInputTextChange}
                            onKeyDown={onKeyDown}
                            ref={inputRef}
                            disabled={disableControl}
                        />
                        <GoButton
                            onClick={handleSubmit}
                            disabled={disableControl}
                        >
                            <span>
                                <img
                                    src="/send_button.svg"
                                    height="24"
                                    width="24"
                                    alt="send button image"
                                />
                            </span>
                        </GoButton>
                    </InputWrapper>
                </Container>
            </InputContainer>
        </ChatContainer>
    )
}

export default ChatCompletionPanel
