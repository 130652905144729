/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import { FunctionComponent, useCallback, useState } from 'react'
import { Alert, Modal, CloseButton } from 'react-bootstrap'
import styled from 'styled-components'
import ChatCompletionPanel from './chatCompletionPanel'
import { CodeBlock, dracula } from 'react-code-blocks'
import { postChatCompletion } from '../store/chatCompletion.slice'
import { LoadingState } from '../models/loadingState'
import { audioChatPythonCode } from './code/audioPythonCode'
import { ModelType } from '../models/modelType'
import { genericErrorMessage } from '../models/error'

const AudioModelContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`
const ViewPortControl = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    //margin-top: 20px;
    border-top: solid 1px;
    border-color: rgb(101, 49, 113);
    //border: 1px solid rgb(101, 49, 113);
    min-height: 15rem;
    margin: 1.6rem 1.5rem;
    background-color: #e8e8e8; //rgb(12, 12, 12, 1);
`

const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    justify-content: center;
    border-radius: 0;
`
const ButtonTab = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(44, 44, 44);
    border-radius: 0px;
    border: none;
    color: rgb(224, 224, 224);
    font-family: 'IntelOne Display';
    font-size: 0.8rem;
    font-weight: 500;
    padding: 3px 12px;
    text-transform: capitalize;
    margin-right: 0.5rem;
    &:hover {
        color: rgb(224, 224, 224);
        border: none;
        font-family: 'IntelOne Display';
        font-size: 0.8rem;
        font-weight: 500;
    }
    &:focus {
        color: rgb(224, 224, 224);
        border: none;
        font-family: 'IntelOne Display';
        font-size: 0.8rem;
        font-weight: 500;
    }
    &.active {
        border-bottom: 4px solid #61dafb; //4px solid rgb(84, 255, 46);
        font-weight: 500;
    }
`
const OutputInnerContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 37rem;
    border: 1px solid rgb(101, 49, 113);
    border-radius: 0px;
    padding: 1rem 0;
`

export interface IAudioModelPanelProps {
    postChatQuery: typeof postChatCompletion
    chatCompletionAnswer: any
    chatCompletionLoading: LoadingState
    chatCompletionError: any
    selectedModelName: string
    selectedModelPublishedBy: string
    showModel: boolean
    setShowModel: any
    isNewModelLoad?: boolean
    setIsNewModelLoad?: any
    isStream: boolean
}

const AudioModelPanel: FunctionComponent<IAudioModelPanelProps> = ({
    postChatQuery,
    chatCompletionAnswer,
    chatCompletionLoading,
    chatCompletionError,
    selectedModelName,
    selectedModelPublishedBy,
    showModel,
    setShowModel,
    isNewModelLoad,
    setIsNewModelLoad,
    isStream,
}: IAudioModelPanelProps) => {
    const [currentPromptMessage, setCurrentPromptMessage] = useState<string>('')
    const [selectedTab, setSelectedTab] = useState<string>('preview')
    const [showValidationError, setShowValidationError] = useState<string>('')

    const handlTabClick = useCallback(
        (name: string) => {
            setSelectedTab(name)
        },
        [selectedTab]
    )

    return (
        <AudioModelContainer>
            <Modal
                show={showModel}
                onHide={() => setShowModel((show: boolean) => !show)}
                onShow={() => setShowValidationError((err: string) => '')}
                backdrop="static"
                keyboard={true}
                size="xl"
            >
                <Modal.Body>
                    <CloseButton
                        className="p-1"
                        onClick={() => setShowModel((show: boolean) => !show)}
                        style={{
                            borderRadius: 0,
                            fontSize: '1.5rem',
                            marginRight: '0.3rem',
                        }}
                    />
                    <ViewPortControl>
                        <AlertComponent
                            style={{ width: '100%' }}
                            variant="danger"
                            show={
                                !isNewModelLoad &&
                                (chatCompletionError ||
                                    showValidationError !== '')
                                    ? true
                                    : false
                            }
                        >
                            {chatCompletionError
                                ? genericErrorMessage
                                : showValidationError
                                ? showValidationError
                                : ''}
                        </AlertComponent>
                        <div id="scrollUpToId"></div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        padding: '0.5rem',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            padding: '0.5rem',
                                        }}
                                    >
                                        <ButtonTab
                                            className={
                                                selectedTab === 'preview'
                                                    ? 'active'
                                                    : ''
                                            }
                                            onClick={() =>
                                                handlTabClick('preview')
                                            }
                                        >
                                            AudioQnA Chat
                                        </ButtonTab>
                                        <ButtonTab
                                            className={
                                                selectedTab === 'python'
                                                    ? 'active'
                                                    : ''
                                            }
                                            onClick={() =>
                                                handlTabClick('python')
                                            }
                                        >
                                            Python
                                        </ButtonTab>
                                    </div>
                                </div>

                                <div
                                    id="chat"
                                    style={
                                        selectedTab === 'preview'
                                            ? { display: 'block' }
                                            : { display: 'none' }
                                    }
                                >
                                    <ChatCompletionPanel
                                        postChatQuery={postChatQuery}
                                        chatCompletionAnswer={
                                            chatCompletionAnswer
                                        }
                                        chatCompletionLoading={
                                            chatCompletionLoading
                                        }
                                        chatCompletionError={
                                            chatCompletionError
                                        }
                                        selectedModelName={selectedModelName}
                                        selectedModelPublishedBy={
                                            selectedModelPublishedBy
                                        }
                                        CurrentPromptMessage={
                                            currentPromptMessage
                                        }
                                        setCurrentPromptMessage={
                                            setCurrentPromptMessage
                                        }
                                        isStream={isStream}
                                        modelType={ModelType.Audio}
                                        setShowValidationError={
                                            setShowValidationError
                                        }
                                        isNewModelLoad={isNewModelLoad}
                                        setIsNewModelLoad={setIsNewModelLoad}
                                    ></ChatCompletionPanel>
                                </div>

                                <div
                                    id="code"
                                    style={
                                        selectedTab === 'python'
                                            ? { display: 'block' }
                                            : { display: 'none' }
                                    }
                                >
                                    <OutputInnerContainer>
                                        <pre
                                            style={{
                                                width: '100%',
                                                padding: '1rem',
                                            }}
                                        >
                                            <CodeBlock
                                                theme={dracula}
                                                text={audioChatPythonCode}
                                                language={'Python'}
                                                showLineNumbers={true}
                                            />
                                        </pre>
                                    </OutputInnerContainer>
                                </div>
                            </div>
                        </div>
                    </ViewPortControl>
                </Modal.Body>
            </Modal>
        </AudioModelContainer>
    )
}

export default AudioModelPanel
