export class ChatCompletionRequest {
    public messages:
        | string
        | ChatMessage[]
        | ChatContentMessage[]
        | VisualMessage[]
        | AudioMessage[]
        | null = null
    public model: string | null = null
    public max_tokens?: number = undefined
    public stream?: boolean = false
    public temperature?: number | null = null
    public top_p?: number | undefined = undefined
    public frequency_penalty?: number | undefined = undefined
    public presence_penalty?: number | undefined = undefined
    public seed?: number | undefined = undefined
    public context?: any | undefined
}

export class ChatMessage {
    public role: string | undefined = undefined
    public content: string | undefined = undefined
}

export class ChatContentMessage {
    public role: string | undefined = undefined
    public content: string | undefined = undefined
}
export class VisualMessage {
    public role: string | undefined = undefined
    public content: any[] = []
}
export class AudioMessage {
    public role: string | undefined = undefined
    public content: any[] = []
}
