import { API_SERVICE_URL } from '../config/service'
import { ChatCompletionRequest } from '../models/chatCompletionRequest'
const serviceConfig = require('../config/service.json')
import api from './auth/noAuthClient'
import { RetrievalModelRequest } from '../models/retrievalModelRequest'

class ChatCompletionService {
    getAIModels(qParam: any) {
        var url = API_SERVICE_URL + serviceConfig.getAIModels + qParam
        return api.get(url)
    }
    async SubmitChatQuery(request: ChatCompletionRequest) {
        var url = API_SERVICE_URL + serviceConfig.aiModelChatCompletion
        return api.post(url, request)
    }

    SubmitRetrievalQuery(request: RetrievalModelRequest) {
        var url = API_SERVICE_URL + serviceConfig.aiModelEmbeddings
        return api.post(url, request)
    }
}
export default new ChatCompletionService()
