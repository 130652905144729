/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import {
    FunctionComponent,
    Dispatch,
    useState,
    useCallback,
    FormEvent,
    useEffect,
} from 'react'
import { connect } from 'react-redux'
import { LoadingState } from '../models/loadingState'
import SideLinkPanel from '../layouts/sidePanel'
import AiModelHeaderPanel from '../layouts/header'
import AiModelCard from '../layouts/aiModelCard'
import AiModelFooterPanel from '../layouts/footer'
import { LayoutStylePreference } from '../models/layoutStyle'
import { Alert, CloseButton, Modal, Spinner } from 'react-bootstrap'
import ChatCompletionPanel from '../components/chatCompletionPanel'
import OutputPanel from '../components/outputPanel'
import { getAIModels, postChatCompletion } from '../store/chatCompletion.slice'
import { ChatCompletionRequest } from '../models/chatCompletionRequest'
import EmblaCarousel from '../components/emblaCarousel/EmblaCarousel'
import { EmblaOptionsType } from 'embla-carousel'
import '../components/emblaCarousel/css/embla.css'
import { setScrollIntoViewWithId } from '../lib/scroll'
import { ModelSubType } from '../models/modelType'
import { genericErrorMessage } from '../models/error'

const ComparePanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (max-width: 500px) {
        width: 100%;
        height: 100%;
    }
    overflow-y: auto;
    overflow-x: hidden;
    background-color: rgb(24, 24, 24, 0.5);

    /* scroll style-width */
    ::-webkit-scrollbar {
        height: 1rem;
        width: 0.8rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        // background: #f1f1f1;
        // border-radius: 20px;
        background-color: transparent;
        border-radius: 9999px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        // background: #888;
        // border-radius: 20px;
        --tw-border-opacity: 1;
        background-color: hsla(0, 0%, 89%, 0.8);
        border-color: rgba(255, 255, 255, var(--tw-border-opacity));
        border-radius: 9999px;
        border-width: 1px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`

const CompareHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
    margin-top: 3.5rem;
`
const CompareBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    width: 100%;
    background-color: #fefefe;
    overflow-y: auto;
`
const SidePanelComponent = styled.div`
    display: block;
    @media screen and (max-width: 600px) {
        display: none;
    }
`

const MainSection = styled.section`
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 0;
    padding: 2rem 1rem 3rem 1rem;
    background-color: #e8e8e8; //rgb(12, 12, 12, 1);
    margin: 1rem 1.5rem;
`

const ViewPortControl = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    //margin-top: 20px;
    border-top: solid 1px;
    border-color: rgb(101, 49, 113);
    //border: 1px solid rgb(101, 49, 113);
    min-height: 15rem;
    margin: 1.6rem 1.5rem;
    background-color: #e8e8e8; //rgb(12, 12, 12, 1);
`

const WarningDiv = styled.div`
    display: flex;
    text-align: left;
    justify-content: left;
    font-size: 1rem;
    font-family: 'IntelOne Text';
    font-weight: 400;
    color: #fff; //rgba(0, 0, 0, 0.85);
    background: #202020;
    padding: 1rem;
    margin: 1rem 0;
    border-radius: 0;
    @media screen and (max-width: 1900px) {
        font-size: 0.8rem;
    }
`
const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    justify-content: center;
    border-radius: 0;
    width: 100%;
`
const ButtonTab = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(44, 44, 44);
    border-radius: 0px;
    border: none;
    color: rgb(224, 224, 224);
    font-family: 'IntelOne Display';
    font-size: 0.8rem;
    font-weight: 500;
    padding: 3px 12px;
    text-transform: capitalize;
    margin-right: 0.5rem;
    &:hover {
        color: rgb(224, 224, 224);
        border: none;
        font-family: 'IntelOne Display';
        font-size: 0.8rem;
        font-weight: 500;
    }
    &:focus {
        color: rgb(224, 224, 224);
        border: none;
        font-family: 'IntelOne Display';
        font-size: 0.8rem;
        font-weight: 500;
    }
    &.active {
        border-bottom: 4px solid #61dafb; //4px solid rgb(84, 255, 46);
        font-weight: 500;
    }
`

const SpinnerDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    width: 100%;
`
const ModalPopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const CompareButton = styled.button`
    font-family: 'IntelOne Text';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    text-decoration: none;
    color: #fff;
    background-color: #8f5da2;
    margin: 1.5rem;
    border: none;
    min-width: 10rem;
    height: 2rem;
    border-radius: 0;
    border: none;
    &:disabled {
        opacity: 0.1;
        --tw-text-opacity: 1;
    }
`
export interface ICompareContainerProps {
    postChatQuery: typeof postChatCompletion
    chatCompletionAnswer: any
    chatCompletionLoading: LoadingState
    chatCompletionError: any
    getAIModels: typeof getAIModels
    getAIModelsResultData: any
    getAIModelsLoading: LoadingState
    getAIModelsError: any
}

const CompareContainer: FunctionComponent<ICompareContainerProps> & {
    defaultProps: Partial<ICompareContainerProps>
} = ({
    getAIModels,
    getAIModelsResultData,
    getAIModelsLoading,
    getAIModelsError,
    postChatQuery,
    chatCompletionAnswer,
    chatCompletionLoading,
    chatCompletionError,
}: ICompareContainerProps) => {
    const [compareLLMModelsList, setCompareLLMModelsList] = useState<any>()

    const [compareVectorModelsList, setCompareVectorModelsList] =
        useState<any>()
    const [showModelDetailControl, setShowModelDetailControl] =
        useState<boolean>(false)
    const [isNewModelLoad, setIsNewModelLoad] = useState<boolean>(false)
    const [moduleDisplayGroup, setModuleDisplayGroup] = useState<number>(1)
    const [selectedModelName, setSelectedModelName] = useState<string>('')
    const [selectedModelPublishedBy, setSelectedModelPublishedBy] =
        useState<string>('')
    const [currentPromptMessage, setCurrentPromptMessage] = useState<string>('')
    const [selectedOutputTab, setSelectedOutputTab] = useState<string>('result')
    const [compareResult, setCompareResult] = useState<
        {
            role: string
            content: string
            model?: string
            metric?: string[]
        }[]
    >([])
    const [clickedLLMCards, setClickedLLMCards] = useState<number[]>([])
    const [clickedVectorDbCards, setClickedVectorDbCards] = useState<number[]>(
        []
    )

    useEffect(() => {
        getAIModels('?compare=llm&compare=vectordb&count=50')
    }, [])

    useEffect(() => {
        if (getAIModelsResultData && getAIModelsResultData.models) {
            const compareList = getAIModelsResultData.models.map(
                (item: any) => {
                    return {
                        ...item,
                        isChecked: false,
                        showCheckboxPanel: item.isActive ? true : false,
                        isRagModel: item.name.startsWith('rag-') ? true : false,
                    }
                }
            )

            setCompareLLMModelsList(
                compareList.filter((item: any) => item.tags['Compare'] == 'llm')
            )
            setCompareVectorModelsList(
                compareList.filter(
                    (item: any) => item.tags['Compare'] == 'vectordb'
                )
            )
        }
    }, [getAIModelsResultData])

    const onCardClick = useCallback(
        (
            event: FormEvent<HTMLElement>,
            modelName: string,
            displayName: string,
            publishedBy: string,
            isActive: boolean,
            index: number,
            subType: ModelSubType
        ) => {
            let count = 0
            if (isActive) {
                setSelectedModelName(modelName)
                setSelectedModelPublishedBy(publishedBy)
                if (subType == ModelSubType.Llms) {
                    setClickedLLMCards((prev: number[]) => {
                        if (prev.includes(index)) {
                            return prev.filter((i) => i != index)
                        } else {
                            return [...prev, index]
                        }
                    })
                    compareVectorModelsList?.map((item: any) => {
                        item.isChecked = false
                    })
                    setClickedVectorDbCards([])
                    const updatedList = compareLLMModelsList.map(
                        (item: any) => {
                            if (displayName === item.displayName) {
                                item.isChecked = !item.isChecked
                            }
                            if (item.isChecked) count = count + 1

                            return item
                        }
                    )
                    setCompareLLMModelsList(updatedList)
                } else if (subType == ModelSubType.vectorDb) {
                    setClickedVectorDbCards((prev: number[]) => {
                        if (prev.includes(index)) {
                            return prev.filter((i) => i != index)
                        } else {
                            return [...prev, index]
                        }
                    })
                    compareLLMModelsList?.map((item: any) => {
                        item.isChecked = false
                    })
                    setClickedLLMCards([])
                    const updatedList = compareVectorModelsList?.map(
                        (item: any) => {
                            if (displayName === item.displayName) {
                                item.isChecked = !item.isChecked
                            }
                            if (item.isChecked) count = count + 1

                            return item
                        }
                    )
                    setCompareVectorModelsList(updatedList)
                }
            }
        },
        [
            showModelDetailControl,
            compareLLMModelsList,
            compareVectorModelsList,
            clickedLLMCards,
        ]
    )
    useEffect(() => {
        if (showModelDetailControl) {
            setScrollIntoViewWithId('scrollUpToId')
        }
    }, [showModelDetailControl])

    const handleCompareClick = () => {
        setShowModelDetailControl(true)
        setIsNewModelLoad(true)
    }

    const OPTIONS: EmblaOptionsType = { slidesToScroll: 'auto' }
    const LLM_SLIDES = (
        <div>
            {compareLLMModelsList &&
                compareLLMModelsList
                    .filter((item: any) => !item.tags['HideOnCompare'])
                    .map((item: any, index: number) => (
                        <div key={`card-${index}`}>
                            <AiModelCard
                                imageURL={item.logo}
                                modelName={item.name}
                                displayName={item.displayName}
                                publishedBy={item.author}
                                description={item.description}
                                onCardClickCallBack={(e: any) =>
                                    onCardClick(
                                        e,
                                        item.name,
                                        item.displayName,
                                        item.author,
                                        item.isActive,
                                        index,
                                        item?.tags['Compare']
                                    )
                                }
                                category={item?.tags['Feature']}
                                renderStyle={LayoutStylePreference.vStyle}
                                isActive={item.isActive}
                                showCheckboxPanel={item.showCheckboxPanel}
                                isChecked={item.isChecked}
                                index={index}
                            />
                        </div>
                    ))}
        </div>
    )

    const compareLLMSection = (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '7%',
                }}
            >
                <h6>Select the LLM models and click evaluate.</h6>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                }}
            >
                {compareLLMModelsList && compareLLMModelsList.length > 0 ? (
                    <EmblaCarousel slides={LLM_SLIDES} options={OPTIONS} />
                ) : (
                    ''
                )}
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <CompareButton
                    disabled={clickedLLMCards.length < 1}
                    onClick={handleCompareClick}
                >
                    Evaluate
                </CompareButton>
            </div>
        </div>
    )
    const VectorDB_SLIDES = (
        <div>
            {compareVectorModelsList &&
                compareVectorModelsList.map((item: any, index: number) => (
                    <div key={`card-${index}`}>
                        <AiModelCard
                            imageURL={item.logo}
                            modelName={item.name}
                            displayName={item.displayName}
                            publishedBy={item.author}
                            description={item.description}
                            onCardClickCallBack={(e: any) =>
                                onCardClick(
                                    e,
                                    item.name,
                                    item.displayName,
                                    item.author,
                                    item.isActive,
                                    index,
                                    item?.tags['Compare']
                                )
                            }
                            category={item?.tags['Feature']}
                            renderStyle={LayoutStylePreference.vStyle}
                            isActive={item.isActive}
                            showCheckboxPanel={item.showCheckboxPanel}
                            isChecked={item.isChecked}
                            index={index}
                        />
                    </div>
                ))}
        </div>
    )
    const compareVectorDBSection = (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '8%',
                }}
            >
                <h6>Select vector db and click on evaluate.</h6>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                }}
            >
                {compareVectorModelsList &&
                compareVectorModelsList.length > 0 ? (
                    <EmblaCarousel slides={VectorDB_SLIDES} options={OPTIONS} />
                ) : (
                    ''
                )}
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <CompareButton
                    disabled={clickedVectorDbCards.length < 1}
                    onClick={handleCompareClick}
                >
                    Evaluate
                </CompareButton>
            </div>
        </div>
    )

    return (
        <ComparePanelContainer>
            <AiModelHeaderPanel></AiModelHeaderPanel>
            <CompareHorizontalContainer>
                <SidePanelComponent>
                    <SideLinkPanel></SideLinkPanel>
                </SidePanelComponent>

                <CompareBodyContainer>
                    {getAIModelsError && (
                        <AlertComponent
                            variant="danger"
                            show={getAIModelsError ? true : false}
                        >
                            {genericErrorMessage}
                        </AlertComponent>
                    )}
                    {getAIModelsLoading !== LoadingState.Pending ? (
                        <MainSection style={{ marginTop: '1.6rem' }}>
                            <div>{compareLLMSection}</div>
                            <div
                                style={{
                                    margin: '1rem 3rem 2rem 3rem',
                                    borderTop: '1px solid rgb(143, 93, 162)',
                                }}
                            ></div>
                            <div> {compareVectorDBSection} </div>

                            <ModalPopupContainer>
                                <Modal
                                    show={
                                        showModelDetailControl &&
                                        (clickedLLMCards.length > 0 ||
                                            clickedVectorDbCards.length > 0)
                                    }
                                    onHide={() =>
                                        setShowModelDetailControl(
                                            (show) => !show
                                        )
                                    }
                                    backdrop="static"
                                    keyboard={true}
                                    size="xl"
                                >
                                    <Modal.Body>
                                        <CloseButton
                                            className="p-1"
                                            onClick={() =>
                                                setShowModelDetailControl(
                                                    (show) => !show
                                                )
                                            }
                                            style={{
                                                borderRadius: 0,
                                                fontSize: '1.5rem',
                                                marginRight: '0.3rem',
                                            }}
                                        />
                                        <ViewPortControl>
                                            <AlertComponent
                                                style={{ width: '100%' }}
                                                variant="danger"
                                                show={
                                                    chatCompletionError
                                                        ? true
                                                        : false
                                                }
                                            >
                                                {genericErrorMessage}
                                            </AlertComponent>
                                            <div id="scrollUpToId"></div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        width: '50%',
                                                        height: '100%',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            padding: '0.5rem',
                                                        }}
                                                    >
                                                        <ButtonTab>
                                                            Preview
                                                        </ButtonTab>
                                                        {/* <ButtonTab>Json</ButtonTab> */}
                                                    </div>
                                                    <div id="chatDiv">
                                                        <ChatCompletionPanel
                                                            postChatQuery={
                                                                postChatQuery
                                                            }
                                                            chatCompletionAnswer={
                                                                chatCompletionAnswer
                                                            }
                                                            chatCompletionLoading={
                                                                chatCompletionLoading
                                                            }
                                                            chatCompletionError={
                                                                chatCompletionError
                                                            }
                                                            selectedModelName={
                                                                selectedModelName
                                                            }
                                                            selectedModelPublishedBy={
                                                                selectedModelPublishedBy
                                                            }
                                                            CurrentPromptMessage={
                                                                currentPromptMessage
                                                            }
                                                            setCurrentPromptMessage={
                                                                setCurrentPromptMessage
                                                            }
                                                            isStream={false}
                                                            isCompare={true}
                                                            compareList={
                                                                clickedLLMCards &&
                                                                clickedLLMCards.length >
                                                                    0
                                                                    ? compareLLMModelsList.filter(
                                                                          (
                                                                              item: any
                                                                          ) =>
                                                                              item.isChecked ===
                                                                              true
                                                                      )
                                                                    : clickedVectorDbCards &&
                                                                      clickedVectorDbCards.length >
                                                                          0
                                                                    ? compareVectorModelsList.filter(
                                                                          (
                                                                              item: any
                                                                          ) =>
                                                                              item.isChecked ===
                                                                              true
                                                                      )
                                                                    : ''
                                                            }
                                                            compareResult={
                                                                compareResult
                                                            }
                                                            setCompareResult={
                                                                setCompareResult
                                                            }
                                                            isNewModelLoad={
                                                                isNewModelLoad
                                                            }
                                                            setIsNewModelLoad={
                                                                setIsNewModelLoad
                                                            }
                                                        ></ChatCompletionPanel>
                                                    </div>
                                                </div>
                                                <div
                                                    id="seprator"
                                                    style={{
                                                        width: '1px',
                                                        margin: '0 1rem',
                                                        backgroundColor:
                                                            'rgb(101, 49, 113)',
                                                    }}
                                                ></div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        width: '50%',
                                                        height: '100%',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            padding: '0.5rem',
                                                        }}
                                                    >
                                                        <ButtonTab
                                                            className={
                                                                selectedOutputTab ===
                                                                'container'
                                                                    ? 'active'
                                                                    : ''
                                                            }
                                                        >
                                                            Result
                                                        </ButtonTab>
                                                    </div>
                                                    <div id="outputDiv">
                                                        <OutputPanel
                                                            chatMessage={
                                                                currentPromptMessage
                                                                    ? currentPromptMessage
                                                                    : `Ask a question`
                                                            }
                                                            selectedModelName={
                                                                selectedModelName
                                                            }
                                                            isCompareOutput={
                                                                true
                                                            }
                                                            compareOutputData={
                                                                compareResult
                                                            }
                                                            isStream={false}
                                                        ></OutputPanel>
                                                    </div>
                                                </div>
                                            </div>
                                        </ViewPortControl>
                                    </Modal.Body>
                                </Modal>
                            </ModalPopupContainer>
                        </MainSection>
                    ) : (
                        <SpinnerDiv>
                            <Spinner animation="border" variant="light">
                                <span className="visually-hidden">
                                    Loading...
                                </span>
                            </Spinner>
                        </SpinnerDiv>
                    )}

                    <AiModelFooterPanel></AiModelFooterPanel>
                </CompareBodyContainer>
            </CompareHorizontalContainer>
        </ComparePanelContainer>
    )
}

CompareContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        getAIModels: (qParam: any) => {
            dispatch(getAIModels(qParam))
        },
        postChatQuery: (question: ChatCompletionRequest) =>
            dispatch(postChatCompletion(question)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        getAIModelsResultData: state.getAIModelsResult.data,
        getAIModelsLoading: state.getAIModelsResult.loading,
        getAIModelsError: state.getAIModelsResult.error,

        chatCompletionAnswer: state.postChatCompletionResult.data,
        chatCompletionLoading: state.postChatCompletionResult.loading,
        chatCompletionError: state.postChatCompletionResult.error,
    }
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(CompareContainer)
