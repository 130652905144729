/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import './index.css'
import './fonts/IntelClearPro_W_Bd.woff'
import './fonts/intelone-display-light.woff2'
import './fonts/intelone-display-regular.woff'
import './fonts/intelone-text-regular.woff'
import reportWebVitals from './reportWebVitals'
import store from './store/store'
import 'bootstrap/dist/css/bootstrap.min.css'
import { ThemeProvider } from 'styled-components'
import { defaultTheme } from './themes/defaultTheme/theme'

import DiscoverContainer from './containers/discoverContainer'
import OtherModelContainer from './containers/OtherModelContainer'
import RetrievalContainer from './containers/retrievalContainer'
import CompareContainer from './containers/compareContainer'
import VisualContainer from './containers/visualContainer'
import SummarizationContainer from './containers/summarizationContainer'
import TranslationContainer from './containers/translationContainer'
import { UAParser } from 'ua-parser-js'
import UnSupportedBrowserError from './layouts/unSupportedBrowserError'
import StringUtils from './lib/stringUtils'
import { APP_BASE_URL } from './config/service'
import PageNotFoundContainer from './containers/pageNotFoundContainer'
import AudioContainer from './containers/audioContainer'
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

console.log('v0.0')
let ugerAgent = navigator.userAgent
let isChromeBrowser: boolean = true
let isAllowedOtherBrowser: boolean = false
const ua = new UAParser(ugerAgent)
let browserName = ua.getBrowser().name
if (
    browserName !== undefined &&
    !(browserName == 'Chrome' || browserName == 'Edge')
) {
    isChromeBrowser = false
}
let allowOtherBrowser = localStorage.getItem('isAllowedOtherBrowser')
if (!StringUtils.isNullOrUndefinedOrEmpty(allowOtherBrowser)) {
    isAllowedOtherBrowser = true
}
root.render(
    // <React.StrictMode>
    <ThemeProvider theme={defaultTheme}>
        <Provider store={store}>
            <BrowserRouter basename={APP_BASE_URL ? APP_BASE_URL : ''}>
                <Routes>
                    <Route
                        index
                        element={
                            isChromeBrowser || isAllowedOtherBrowser ? (
                                <DiscoverContainer />
                            ) : (
                                <UnSupportedBrowserError />
                            )
                        }
                    />
                    <Route path="/home" element={<DiscoverContainer />} />

                    {/* aiModels routings start*/}
                    <Route path="/explore" element={<DiscoverContainer />} />
                    <Route
                        path="/explore/retrieval"
                        element={<RetrievalContainer />}
                    />
                    <Route
                        path="/explore/search"
                        element={<OtherModelContainer />}
                    />
                    <Route
                        path="/explore/visual"
                        element={<VisualContainer />}
                    />
                    <Route
                        path="/explore/evaluate"
                        element={<CompareContainer />}
                    />
                    <Route
                        path="/explore/summarization"
                        element={<SummarizationContainer />}
                    />
                    <Route
                        path="/explore/translation"
                        element={<TranslationContainer />}
                    />
                    <Route path="/explore/audio" element={<AudioContainer />} />

                    {/* aiModels routings ends*/}

                    <Route path="*" element={<PageNotFoundContainer />} />
                </Routes>
            </BrowserRouter>
        </Provider>
    </ThemeProvider>
    // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
