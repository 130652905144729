import { combineReducers } from '@reduxjs/toolkit'

import {
    getAIModelsResult,
    postChatCompletionResult,
    postRetrievalQueryResult,
} from './chatCompletion.slice'
import { submitImageGenerationResult } from './imageGeneration.slice'

const rootReducer = combineReducers({
    getAIModelsResult,
    postChatCompletionResult,
    postRetrievalQueryResult,
    submitImageGenerationResult,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
