/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import {
    FunctionComponent,
    Dispatch,
    useState,
    useCallback,
    FormEvent,
    useEffect,
} from 'react'
import { connect } from 'react-redux'
import { LoadingState } from '../models/loadingState'
import SideLinkPanel from '../layouts/sidePanel'
import AiModelHeaderPanel from '../layouts/header'
import AiModelCard from '../layouts/aiModelCard'
import AiModelFooterPanel from '../layouts/footer'
import { LayoutStylePreference } from '../models/layoutStyle'
import { getAIModels, postRetrievalQuery } from '../store/chatCompletion.slice'
import { RetrievalModelRequest } from '../models/retrievalModelRequest'
import { setScrollIntoViewWithId } from '../lib/scroll'
import RetrievalModelPanel from '../components/retrievalModelPanel'
import { Alert, Spinner } from 'react-bootstrap'
import { genericErrorMessage } from '../models/error'

const RetrievalPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (max-width: 500px) {
        width: 100%;
        height: 100%;
    }

    overflow-y: auto;
    overflow-x: hidden;
    background-color: rgb(24, 24, 24, 0.5);

    /* scroll style-width */
    ::-webkit-scrollbar {
        height: 1rem;
        width: 0.8rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        // background: #f1f1f1;
        // border-radius: 20px;
        background-color: transparent;
        border-radius: 9999px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        // background: #888;
        // border-radius: 20px;
        --tw-border-opacity: 1;
        background-color: hsla(0, 0%, 89%, 0.8);
        border-color: rgba(255, 255, 255, var(--tw-border-opacity));
        border-radius: 9999px;
        border-width: 1px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`

const RetrievalHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: flex-start;
    margin-top: 3.5rem;
`
const RetrievalBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: flex-start;
    width: 100%;
    background-color: #fefefe;
    overflow-y: auto;
`
const SidePanelComponent = styled.div`
    display: block;
    @media screen and (max-width: 600px) {
        display: none;
    }
`

const MainSection = styled.section`
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 0;
    padding: 2rem 1rem 3rem 1rem;
    background-color: #e8e8e8; //rgb(12, 12, 12, 1);
    margin: 1.6rem 1.5rem;
`

const CardColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: '0.1rem';
`

const CardPanelContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
`
const SpinnerDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    width: 100%;
`
const AlertComponent = styled(Alert)`
    display: flex;
    margin: 1rem 2rem;
    justify-content: center;
    border-radius: 0;
    width: 95%;
`

export interface IRetrievalContainerProps {
    postRetrievalQuery: typeof postRetrievalQuery
    retrievalQueryAnswer: any
    retrievalQueryLoading: LoadingState
    retrievalQueryError: any
    getAIModels: typeof getAIModels
    getAIModelsResultData: any
    getAIModelsLoading: LoadingState
    getAIModelsError: any
}

const RetrievalContainer: FunctionComponent<IRetrievalContainerProps> & {
    defaultProps: Partial<IRetrievalContainerProps>
} = ({
    getAIModels,
    getAIModelsResultData,
    getAIModelsLoading,
    getAIModelsError,
    postRetrievalQuery,
    retrievalQueryAnswer,
    retrievalQueryLoading,
    retrievalQueryError,
}: IRetrievalContainerProps) => {
    const [showModelDetailControl, setShowModelDetailControl] =
        useState<boolean>(false)
    const [selectedModelName, setSelectedModelName] = useState<string>('')
    const [selectedModelPublishedBy, setSelectedModelPublishedBy] =
        useState<string>('')
    const [isNewModelLoad, setIsNewModelLoad] = useState<boolean>(false)

    useEffect(() => {
        getAIModels('?use-case=retrieval&count=50')
    }, [])

    const onCardClick = useCallback(
        (
            event: FormEvent<HTMLElement>,
            modelName: string,
            publishedBy: string,
            isActive: boolean
        ) => {
            if (isActive) {
                setSelectedModelName(modelName)
                setSelectedModelPublishedBy(publishedBy)
                setShowModelDetailControl((prevState) => !prevState)
            }
        },
        [showModelDetailControl]
    )

    useEffect(() => {
        if (showModelDetailControl) {
            setScrollIntoViewWithId('scrollUpToId')
        }
    }, [showModelDetailControl])

    const cards = (
        <div
            style={{
                display: 'flex',
                justifyContent: 'left',
                flexDirection: 'row',
                flexWrap: 'wrap',
            }}
        >
            {getAIModelsResultData &&
                getAIModelsResultData.models &&
                getAIModelsResultData.models.map((item: any, index: number) => (
                    <CardColumn key={`card-${index}`}>
                        <AiModelCard
                            imageURL={item.logo}
                            modelName={item.name}
                            displayName={item.displayName}
                            publishedBy={item.author}
                            description={item.description}
                            onCardClickCallBack={(e: any) =>
                                onCardClick(
                                    e,
                                    item.name,
                                    item.author,
                                    item.isActive
                                )
                            }
                            category={item?.tags['Feature']}
                            renderStyle={LayoutStylePreference.vStyle}
                            isActive={item.isActive}
                            index={index}
                        />
                    </CardColumn>
                ))}
        </div>
    )

    const retrievalModelsSection = (
        <div>
            <CardPanelContainer>{cards}</CardPanelContainer>
        </div>
    )
    return (
        <RetrievalPanelContainer>
            <AiModelHeaderPanel></AiModelHeaderPanel>
            <RetrievalHorizontalContainer>
                <SidePanelComponent>
                    <SideLinkPanel></SideLinkPanel>
                </SidePanelComponent>
                <RetrievalBodyContainer>
                    {getAIModelsError && (
                        <AlertComponent
                            variant="danger"
                            show={getAIModelsError ? true : false}
                        >
                            {genericErrorMessage}
                        </AlertComponent>
                    )}
                    {getAIModelsLoading !== LoadingState.Pending ? (
                        <MainSection>
                            {retrievalModelsSection}{' '}
                            <RetrievalModelPanel
                                postRetrievalQuery={postRetrievalQuery}
                                retrievalQueryAnswer={retrievalQueryAnswer}
                                retrievalQueryLoading={retrievalQueryLoading}
                                retrievalQueryError={retrievalQueryError}
                                selectedModelName={selectedModelName}
                                selectedModelPublishedBy={
                                    selectedModelPublishedBy
                                }
                                showModel={showModelDetailControl}
                                setShowModel={setShowModelDetailControl}
                                isNewModelLoad={isNewModelLoad}
                                setIsNewModelLoad={setIsNewModelLoad}
                            ></RetrievalModelPanel>
                        </MainSection>
                    ) : (
                        <SpinnerDiv>
                            <Spinner animation="border" variant="light">
                                <span className="visually-hidden">
                                    Loading...
                                </span>
                            </Spinner>
                        </SpinnerDiv>
                    )}

                    <AiModelFooterPanel></AiModelFooterPanel>
                </RetrievalBodyContainer>
            </RetrievalHorizontalContainer>
        </RetrievalPanelContainer>
    )
}

RetrievalContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        getAIModels: (qParam: any) => dispatch(getAIModels(qParam)),
        postRetrievalQuery: (request: RetrievalModelRequest) =>
            dispatch(postRetrievalQuery(request)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        getAIModelsResultData: state.getAIModelsResult.data,
        getAIModelsLoading: state.getAIModelsResult.loading,
        getAIModelsError: state.getAIModelsResult.error,

        retrievalQueryAnswer: state.postRetrievalQueryResult.data,
        retrievalQueryLoading: state.postRetrievalQueryResult.loading,
        retrievalQueryError: state.postRetrievalQueryResult.error,
    }
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(RetrievalContainer)
