/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import {
    FunctionComponent,
    useState,
    useCallback,
    useRef,
    ChangeEvent,
    useEffect,
} from 'react'
import { LoadingState } from '../models/loadingState'
import {
    Alert,
    CloseButton,
    Form,
    FormLabel,
    Modal,
    OverlayTrigger,
    Popover,
} from 'react-bootstrap'
import OutputPanel from './outputPanel'
import '../components/emblaCarousel/css/embla.css'
import React from 'react'
import { SubmitImageGeneration } from '../store/imageGeneration.slice'
import { ImageGenerationRequest } from '../models/imageGenerationRequest'
import { genericErrorMessage } from '../models/error'

const ViewPortControl = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    //margin-top: 20px;
    border-top: solid 1px;
    border-color: rgb(101, 49, 113);
    //border: 1px solid rgb(101, 49, 113);
    min-height: 15rem;
    margin: 1.6rem 1.5rem;
    background-color: #e8e8e8; //rgb(12, 12, 12, 1);
`

const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    justify-content: center;
    border-radius: 0;
`
const ButtonTab = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(44, 44, 44);
    border-radius: 0px;
    border: none;
    color: rgb(224, 224, 224);
    font-family: 'IntelOne Display';
    font-size: 0.8rem;
    font-weight: 500;
    padding: 3px 12px;
    text-transform: capitalize;
    margin-right: 0.5rem;
    &:hover {
        color: rgb(224, 224, 224);
        border: none;
        font-family: 'IntelOne Display';
        font-size: 0.8rem;
        font-weight: 500;
    }
    &:focus {
        color: rgb(224, 224, 224);
        border: none;
        font-family: 'IntelOne Display';
        font-size: 0.8rem;
        font-weight: 500;
    }
    &.active {
        border-bottom: 4px solid #61dafb; //4px solid rgb(84, 255, 46);
        font-weight: 500;
    }
    &:disabled {
        opacity: 0.5;
        --tw-text-opacity: 0.1;
    }
`
const ModalPopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`
const ChatContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    border: 1px solid rgb(101, 49, 113);
    border-radius: 0;
    padding: 1rem 0;
`
const InputContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 30.2rem;
`
const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    alignitems: center;
    height: 100%;
    width: 100%;
    margin-top: 1rem;
    padding: 1rem;
    @media screen and (max-width: 500px) {
        width: 100%;
    }
`
const TextLabel = styled(FormLabel)`
    display: flex;
    color: var(--input-label-light-default, #494b51);
    font-family: 'IntelOne Display';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
`
const PopOverKeyDiv = styled.div`
    background-color: #000;
    color: #fff;
    font-size: 1rem;
    padding: 0.5rem;
`
const RunButton = styled.button`
    font-family: 'IntelOne Text';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    text-decoration: none;
    color: #fff;
    background-color: #8f5da2;
    margin-right: 1.5rem;
    border: none;
    min-width: 6rem;
    height: 2rem;
    border-radius: 0;
    border: none;
    &:disabled {
        background-color: gray;
    }
`
const ResetButton = styled(RunButton)`
    background-color: #8f5da2;
`

export interface IImageGenerationModelProps {
    submitImageGeneration: typeof SubmitImageGeneration
    submitImageGenerationResult: any
    submitImageGenerationLoading: LoadingState
    submitImageGenerationError: any
    selectedModelName: string
    selectedModelPublishedBy: string
    showModel: boolean
    setShowModel: any
    isNewModelLoad?: boolean
    setIsNewModelLoad?: any
    isStream: boolean
}

const ImageGenerationModelPanel: FunctionComponent<
    IImageGenerationModelProps
> = ({
    submitImageGeneration,
    submitImageGenerationResult,
    submitImageGenerationLoading,
    submitImageGenerationError,
    selectedModelName,
    selectedModelPublishedBy,
    showModel,
    setShowModel,
    isNewModelLoad,
    setIsNewModelLoad,
    isStream,
}: IImageGenerationModelProps) => {
    const totalAttempt = 50
    const inputRef = useRef<HTMLTextAreaElement>(null)
    const defaultInputQuery = 'A lion in the water'
    const defaultImagePath = '/aiModelImage/lion_water.png'
    const [inputText, setInputText] = useState(defaultInputQuery)
    const [currentPromptMessage, setCurrentPromptMessage] = useState<string>('')
    const [selectedOutputTab, setSelectedOutputTab] = useState<string>('output')
    const [outputImageURL, setOutputImageURL] = useState(defaultImagePath)
    const [isResetClick, setIsResetClick] = useState(false)

    const previousAttempt = localStorage.getItem(
        selectedModelName
            ? selectedModelName
            : 'stabilityai/stable-diffusion-2-base'
    )

    const [remainingAttempt, setRemainingAttempt] = useState<number>(
        previousAttempt && Number(window.atob(previousAttempt)) > 0
            ? Number(window.atob(previousAttempt))
            : totalAttempt
    )

    const maxAttemptError =
        'You have reached the max attempt to the Gen AI image generation. Please reach out to the administrator for help!'
    const contactUs = (
        <div style={{ marginLeft: '0.5rem' }}>
            <a href="mailto:optimizations@intel.com"> Contact administrator</a>
        </div>
    )

    const handleOutputButtonTabClick = useCallback(
        (name: string) => {
            setSelectedOutputTab(name)
        },
        [selectedOutputTab]
    )

    const handleGenerateClick = async () => {
        //get answer
        setIsResetClick(false)
        setIsNewModelLoad(false)
        const request = new ImageGenerationRequest()
        request.prompt = inputText
        request.model = selectedModelName
        request.response_format = 'b64_json'
        submitImageGeneration(request)
        setRemainingAttempt((preValue) => preValue - 1)
        localStorage.setItem(
            selectedModelName,
            window.btoa((remainingAttempt - 1).toString())
        )
    }

    const base64ToBlobUsingFetch = async (
        b64_json: string,
        contentType: string
    ) => {
        const response = await fetch(`data:${contentType};base64,${b64_json}`)
        const blob = await response.blob()
        const fileBlobURL = URL.createObjectURL(blob)
        //console.log(fileBlobURL)
        setOutputImageURL(fileBlobURL)
        return fileBlobURL
    }

    useEffect(() => {
        if (
            submitImageGenerationResult &&
            submitImageGenerationLoading !== LoadingState.Pending
        ) {
            try {
                const fileBlobURL = base64ToBlobUsingFetch(
                    submitImageGenerationResult?.b64_json,
                    'image/png'
                )
                console.log(fileBlobURL)
            } catch (e) {
                console.log(`Error:${e}`)
            }
        }
    }, [submitImageGenerationResult, submitImageGenerationLoading])
    const handleResetClick = async () => {
        setInputText(defaultInputQuery)
        if (
            inputRef &&
            inputRef.current !== undefined &&
            inputRef.current !== null
        )
            inputRef.current.value = defaultInputQuery
        //set other input
        setOutputImageURL(defaultImagePath)
        setIsResetClick(true)
    }

    const onInputTextChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLTextAreaElement
        setInputText(target.value)
    }, [])

    return (
        <ModalPopupContainer>
            <Modal
                show={showModel}
                onHide={() => setShowModel((show: boolean) => !show)}
                backdrop="static"
                keyboard={true}
                size="xl"
            >
                <Modal.Body>
                    <CloseButton
                        className="p-1"
                        onClick={() => setShowModel((show: boolean) => !show)}
                        style={{
                            borderRadius: 0,
                            fontSize: '1.5rem',
                            marginRight: '0.3rem',
                        }}
                    />
                    <ViewPortControl>
                        <AlertComponent
                            style={{ width: '100%' }}
                            variant="danger"
                            show={
                                submitImageGenerationError ||
                                remainingAttempt <= 0
                                    ? true
                                    : false
                            }
                        >
                            {remainingAttempt <= 0 ? (
                                <>
                                    {maxAttemptError}
                                    {'  '} {contactUs}
                                </>
                            ) : (
                                ''
                            )}
                            {submitImageGenerationError
                                ? genericErrorMessage
                                : ''}
                        </AlertComponent>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '50%',
                                    height: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        padding: '0.5rem',
                                    }}
                                >
                                    <ButtonTab>Input</ButtonTab>
                                </div>
                                <ChatContainer>
                                    <InputContainer>
                                        <Container>
                                            <TextLabel>
                                                Input
                                                <OverlayTrigger
                                                    placement={'right'}
                                                    delay={{
                                                        show: 250,
                                                        hide: 400,
                                                    }}
                                                    overlay={
                                                        <Popover id="pop_timeline">
                                                            <Popover.Body>
                                                                <PopOverKeyDiv>
                                                                    Enter a
                                                                    prompt to
                                                                    receive an
                                                                    AI-generated
                                                                    image.
                                                                </PopOverKeyDiv>
                                                            </Popover.Body>
                                                        </Popover>
                                                    }
                                                >
                                                    <i
                                                        className="uil uil-info-circle pointer"
                                                        tabIndex={0}
                                                    ></i>
                                                </OverlayTrigger>
                                            </TextLabel>
                                            <Form.Control
                                                ref={inputRef}
                                                id="input"
                                                name="input"
                                                placeholder={
                                                    'Please enter your prompt here...'
                                                }
                                                as="textarea"
                                                rows={5}
                                                onChange={onInputTextChange}
                                                defaultValue={defaultInputQuery}
                                                autoFocus
                                            />
                                        </Container>

                                        <Container
                                            style={{ flexDirection: 'row' }}
                                        >
                                            <ResetButton
                                                onClick={handleResetClick}
                                            >
                                                Reset
                                            </ResetButton>
                                            <RunButton
                                                onClick={handleGenerateClick}
                                                disabled={
                                                    remainingAttempt === 0
                                                        ? true
                                                        : false
                                                }
                                            >
                                                Generate
                                            </RunButton>
                                        </Container>
                                    </InputContainer>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <TextLabel
                                            style={{
                                                color: 'rgb(143, 143, 143)',
                                                fontStyle: 'italic',
                                                fontSize: '1rem',
                                                padding: '0 1rem',
                                            }}
                                        >
                                            {remainingAttempt} / {totalAttempt}{' '}
                                            requests remaining
                                        </TextLabel>
                                    </div>
                                </ChatContainer>
                            </div>

                            <div
                                id="seprator"
                                style={{
                                    width: '1px',
                                    margin: '0 1rem',
                                    backgroundColor: 'rgb(101, 49, 113)',
                                }}
                            ></div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '50%',
                                    height: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        padding: '0.5rem',
                                    }}
                                >
                                    <ButtonTab
                                        className={
                                            selectedOutputTab === 'output'
                                                ? 'active'
                                                : ''
                                        }
                                        onClick={() =>
                                            handleOutputButtonTabClick('output')
                                        }
                                    >
                                        Output
                                    </ButtonTab>
                                    <ButtonTab
                                        className={
                                            selectedOutputTab === 'python'
                                                ? 'active'
                                                : ''
                                        }
                                        onClick={() =>
                                            handleOutputButtonTabClick('python')
                                        }
                                    >
                                        Python
                                    </ButtonTab>
                                    <ButtonTab
                                        disabled
                                        className={
                                            selectedOutputTab === 'container'
                                                ? 'active'
                                                : ''
                                        }
                                        onClick={() =>
                                            handleOutputButtonTabClick(
                                                'container'
                                            )
                                        }
                                    >
                                        Container
                                    </ButtonTab>
                                </div>
                                <div id="outputtDiv">
                                    <OutputPanel
                                        isResetClicked={isResetClick}
                                        isDiscoverCodeOutput={true}
                                        selectedOutputTab={selectedOutputTab}
                                        outputImageURL={
                                            isNewModelLoad
                                                ? defaultImagePath
                                                : outputImageURL
                                        }
                                        isVisualOutput={true}
                                        imageGenerationLoading={
                                            submitImageGenerationLoading
                                        }
                                        chatMessage={inputText}
                                        isStream={isStream}
                                    ></OutputPanel>
                                </div>
                            </div>
                        </div>
                    </ViewPortControl>
                </Modal.Body>
            </Modal>
        </ModalPopupContainer>
    )
}

export default ImageGenerationModelPanel
