/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import {
    FunctionComponent,
    Dispatch,
    useState,
    useCallback,
    FormEvent,
    useEffect,
} from 'react'
import { connect } from 'react-redux'
import { LoadingState } from '../models/loadingState'
import SideLinkPanel from '../layouts/sidePanel'
import AiModelHeaderPanel from '../layouts/header'
import AiModelCard from '../layouts/aiModelCard'
import AiModelFooterPanel from '../layouts/footer'
import { LayoutStylePreference } from '../models/layoutStyle'
import TranslationModelPanel from '../components/translationModelPanel'
import { getAIModels, postChatCompletion } from '../store/chatCompletion.slice'
import { ChatCompletionRequest } from '../models/chatCompletionRequest'

import '../components/emblaCarousel/css/embla.css'
import { setScrollIntoViewWithId } from '../lib/scroll'
import { Alert, Spinner } from 'react-bootstrap'
import { genericErrorMessage } from '../models/error'

const TranslationPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (max-width: 500px) {
        width: 100%;
        height: 100%;
    }
    overflow-y: auto;
    overflow-x: hidden;
    background-color: rgb(24, 24, 24, 0.5);

    /* scroll style-width */
    ::-webkit-scrollbar {
        height: 1rem;
        width: 0.8rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        // background: #f1f1f1;
        // border-radius: 20px;
        background-color: transparent;
        border-radius: 9999px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        // background: #888;
        // border-radius: 20px;
        --tw-border-opacity: 1;
        background-color: hsla(0, 0%, 89%, 0.8);
        border-color: rgba(255, 255, 255, var(--tw-border-opacity));
        border-radius: 9999px;
        border-width: 1px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`

const TranslationHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
    margin-top: 3.5rem;
`
const TranslationBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: flex-start;
    width: 100%;
    background-color: #fefefe;
    overflow-y: auto;
`
const SidePanelComponent = styled.div`
    display: block;
    @media screen and (max-width: 600px) {
        display: none;
    }
`

const MainSection = styled.section`
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 0;
    padding: 2rem 1rem 3rem 1rem;
    background-color: #e8e8e8; //rgb(12, 12, 12, 1);
    margin: 1rem 1.5rem;
`
const NextButton = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 2.25rem;
    height: 2.25rem;
    padding: 10px;
    gap: 10px;
    border-radius: 18px;
    cursor: pointer;
    tabindex: 0;
    border: 1px solid #00a3fb;
    &:hover {
        color: #00a3fb;
        border: 1px solid #00a3fb;
    }
    &:focus {
        color: #00a3fb;
        border: 1px solid #00a3fb;
        outline: auto;
    }
    &:disabled {
        color: #00a3fb;
        border: 1px solid transparent;
    }
`
const PreviousButton = styled(NextButton)``
const WarningDiv = styled.div`
    display: flex;
    text-align: left;
    justify-content: left;
    font-size: 1rem;
    font-family: 'IntelOne Text';
    font-weight: 400;
    color: #fff; //rgba(0, 0, 0, 0.85);
    background: #202020;
    padding: 1rem;
    margin: 1rem 0;
    border-radius: 0;
    @media screen and (max-width: 1900px) {
        font-size: 0.8rem;
    }
`

const CardColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: '0.1rem';
`
const SpinnerDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    width: 100%;
`

export interface ITranslationContainerProps {
    postChatQuery: typeof postChatCompletion
    chatCompletionAnswer: any
    chatCompletionLoading: LoadingState
    chatCompletionError: any
    getAIModels: typeof getAIModels
    getAIModelsResultData: any
    getAIModelsLoading: LoadingState
    getAIModelsError: any
}

const CardPanelContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
`
const AlertComponent = styled(Alert)`
    display: flex;
    margin: 1rem 2rem;
    justify-content: center;
    border-radius: 0;
    width: 95%;
`

const TranslationContainer: FunctionComponent<ITranslationContainerProps> & {
    defaultProps: Partial<ITranslationContainerProps>
} = ({
    getAIModels,
    getAIModelsResultData,
    getAIModelsLoading,
    getAIModelsError,
    postChatQuery,
    chatCompletionAnswer,
    chatCompletionLoading,
    chatCompletionError,
}: ITranslationContainerProps) => {
    const [showModelDetailControl, setShowModelDetailControl] =
        useState<boolean>(false)
    const [moduleDisplayGroup, setModuleDisplayGroup] = useState<number>(1)
    const [selectedModelName, setSelectedModelName] = useState<string>('')
    const [selectedModelPublishedBy, setSelectedModelPublishedBy] =
        useState<string>('')
    const [currentPromptMessage, setCurrentPromptMessage] = useState<string>('')
    const [selectedOutputTab, setSelectedOutputTab] = useState<string>('output')
    const [isNewModelLoad, setIsNewModelLoad] = useState<boolean>(false)
    const [isStream, setIsStream] = useState<boolean>(false)

    useEffect(() => {
        getAIModels('?use-case=translation&count=50')
    }, [])

    const onCardClick = useCallback(
        (
            event: FormEvent<HTMLElement>,
            modelName: string,
            displayName: string,
            publishedBy: string,
            isActive: boolean,
            isStream: boolean
        ) => {
            if (isActive) {
                setIsNewModelLoad(true)
                setSelectedModelName(modelName)
                setSelectedModelPublishedBy(publishedBy)
                setShowModelDetailControl((prevState) => !prevState)
                setIsStream(isStream)
            }
        },
        [showModelDetailControl]
    )
    useEffect(() => {
        if (showModelDetailControl) {
            setScrollIntoViewWithId('scrollUpToId')
        }
    }, [showModelDetailControl])

    const cards = (
        <div
            style={{
                display: 'flex',
                justifyContent: 'left',
                flexDirection: 'row',
                flexWrap: 'wrap',
            }}
        >
            {getAIModelsResultData &&
                getAIModelsResultData.models &&
                getAIModelsResultData.models.map((item: any, index: number) => (
                    <CardColumn key={`card-${index}`}>
                        <AiModelCard
                            imageURL={item.logo}
                            modelName={item.name}
                            displayName={item.displayName}
                            publishedBy={item.author}
                            description={item.description}
                            onCardClickCallBack={(e: any) =>
                                onCardClick(
                                    e,
                                    item.name,
                                    item.displayName,
                                    item.author,
                                    item.isActive,
                                    item.isStream
                                )
                            }
                            category={item?.tags['Feature']}
                            renderStyle={LayoutStylePreference.vStyle}
                            isActive={item.isActive}
                            index={index}
                        />
                    </CardColumn>
                ))}
        </div>
    )

    const trendingSection = (
        <div>
            <CardPanelContainer>{cards}</CardPanelContainer>
        </div>
    )

    return (
        <TranslationPanelContainer>
            <AiModelHeaderPanel></AiModelHeaderPanel>
            <TranslationHorizontalContainer>
                <SidePanelComponent>
                    <SideLinkPanel></SideLinkPanel>
                </SidePanelComponent>

                <TranslationBodyContainer>
                    {getAIModelsError && (
                        <AlertComponent
                            variant="danger"
                            show={getAIModelsError ? true : false}
                        >
                            {genericErrorMessage}
                        </AlertComponent>
                    )}
                    {getAIModelsLoading !== LoadingState.Pending ? (
                        <MainSection>
                            {trendingSection}{' '}
                            <TranslationModelPanel
                                postChatQuery={postChatQuery}
                                chatCompletionAnswer={chatCompletionAnswer}
                                chatCompletionLoading={chatCompletionLoading}
                                chatCompletionError={chatCompletionError}
                                selectedModelName={selectedModelName}
                                selectedModelPublishedBy={
                                    selectedModelPublishedBy
                                }
                                setShowModel={setShowModelDetailControl}
                                showModel={showModelDetailControl}
                                isNewModelLoad={isNewModelLoad}
                                setIsNewModelLoad={setIsNewModelLoad}
                                isStream={isStream}
                            />
                        </MainSection>
                    ) : (
                        <SpinnerDiv>
                            <Spinner animation="border" variant="light">
                                <span className="visually-hidden">
                                    Loading...
                                </span>
                            </Spinner>
                        </SpinnerDiv>
                    )}
                    <AiModelFooterPanel></AiModelFooterPanel>
                </TranslationBodyContainer>
            </TranslationHorizontalContainer>
        </TranslationPanelContainer>
    )
}

TranslationContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        getAIModels: (qParam: any) => dispatch(getAIModels(qParam)),
        postChatQuery: (question: ChatCompletionRequest) =>
            dispatch(postChatCompletion(question)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        getAIModelsResultData: state.getAIModelsResult.data,
        getAIModelsLoading: state.getAIModelsResult.loading,
        getAIModelsError: state.getAIModelsResult.error,

        chatCompletionAnswer: state.postChatCompletionResult.data,
        chatCompletionLoading: state.postChatCompletionResult.loading,
        chatCompletionError: state.postChatCompletionResult.error,
    }
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(TranslationContainer)
